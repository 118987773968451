import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IStablecoinPulseAttributes} from '../../query/interface/IStablecoinPulse'
import {useEffect} from 'react'

const StablecoinEditSchema = Yup.object().shape({
  symbol: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Symbol is required'),
  displayPrice: Yup.number()
    .min(0, 'Minimum is 0')
    .max(2, 'Maximum is 2')
    .required('Display Price is required'),
    homeUrl: Yup.string().url().required('Home URL is required'),
})

export const StablecoinEditModal = ({
  id,
  data,
  showStablecoinModal,
  setShowStablecoinModal,
  handleEdit,
}: {
  id: string
  data: IStablecoinPulseAttributes
  showStablecoinModal: boolean
  setShowStablecoinModal: any
  handleEdit: any
}) => {
  const formik = useFormik({
    initialValues: {
      symbol: data.symbol,
      displayPrice: data.display_price,
      homeUrl: data.home_url,
    },
    validationSchema: StablecoinEditSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        handleEdit({
          variables: {
            id,
            symbol: values.symbol,
            displayPrice: values.displayPrice,
            homeUrl: values.homeUrl,
          },
        })
        setShowStablecoinModal(false)
        resetForm()
      } catch (error: any) {
        setStatus('Error: ' + error.message)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('sysbol', data.symbol)
    formik.setFieldValue('displayPrice', data.display_price)
    formik.setFieldValue('homeUrl', data.home_url)
  }, [showStablecoinModal])

  return (
    <>
      <Modal size='lg' show={showStablecoinModal} onHide={() => setShowStablecoinModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Stablecoin Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            onChange={() => formik.setStatus('')}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <div className='row gy-3 gx-5'>
              <div className='col-12'>
                <label className='form-label'>Symbol</label>
                <input
                  placeholder='Symbol'
                  {...formik.getFieldProps('symbol')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.symbol && formik.errors.symbol},
                    {
                      'is-valid': formik.touched.symbol && !formik.errors.symbol,
                    }
                  )}
                  type='text'
                  name='symbol'
                  autoComplete='off'
                />
                {formik.touched.symbol && formik.errors.symbol && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.symbol}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Display Price</label>
                <input
                  placeholder='Display Price'
                  {...formik.getFieldProps('displayPrice')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.displayPrice && formik.errors.displayPrice},
                    {
                      'is-valid': formik.touched.displayPrice && !formik.errors.displayPrice,
                    }
                  )}
                  type='number'
                  name='displayPrice'
                  autoComplete='off'
                />
                {formik.touched.displayPrice && formik.errors.displayPrice && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.displayPrice}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Home URL</label>
                <input
                  placeholder='Home URL'
                  {...formik.getFieldProps('homeUrl')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.homeUrl && formik.errors.homeUrl},
                    {
                      'is-valid': formik.touched.homeUrl && !formik.errors.homeUrl,
                    }
                  )}
                  type='text'
                  name='homeUrl'
                  autoComplete='off'
                />
                {formik.touched.homeUrl && formik.errors.homeUrl && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.homeUrl}</span>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <button className='btn btn-secondary p-3' onClick={() => setShowStablecoinModal(false)}>
            Close
          </button>
          <button className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
