import {useMutation} from '@apollo/client'
import {Column} from 'react-table'
import {Table} from '../../components'
import {useAuth} from '../auth'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {KTSVG} from '../../../_metronic/helpers'
import {useState} from 'react'
import {STABLE_COIN_PULSE_MUTATION} from '../../query/Monitor'
import {
  IStablecoinPulseData,
  IStablecoinPulseAttributes,
} from '../../query/interface/IStablecoinPulse'
import {StablecoinEditModal} from './StablecoinEditModal'

type CustomColumn = {
  className?: string
} & Column

type Props = {
  data: IStablecoinPulseData | undefined
  loading: boolean
  refetch: any
}

export const StablecoinPulseTable = ({data, loading, refetch}: Props) => {
  const {currentUser} = useAuth()

  const [StableCoinPulseMutation] = useMutation(STABLE_COIN_PULSE_MUTATION, {
    onCompleted: () => refetch(),
  })

  const [showStablecoinModal, setShowStablecoinModal] = useState<boolean>(false)
  const [editID, setEditID] = useState<string>('')
  const [editData, setEditData] = useState<IStablecoinPulseAttributes>({
    display_price: '',
    symbol: '',
    home_url: ''
  })

  const handleOpenEditModal = (id: string) => {
    if (!data?.stablecoins.data) return
    const dataEdit = data?.stablecoins.data.find((d) => d.id === id)
    if (dataEdit) {
      setEditData(dataEdit?.attributes)
      setEditID(id)
      setShowStablecoinModal(true)
    }
  }

  const columns: CustomColumn[] = [
    {
      className: 'min-w-300px',
      Header: 'Stable Coin',
      Cell: (cell: any) => {
        return <>{cell.row.original.attributes.symbol}</>
      },
    },
    {
      className: 'min-w-300px',
      Header: 'Display Price',
      Cell: (cell: any) => {
        return <>{cell.row.original.attributes.display_price}</>
      },
    },
    {
      className: 'min-w-300px',
      Header: 'Home URL',
      Cell: (cell: any) => {
        return <>{cell.row.original.attributes.home_url}</>
      },
    },
  ]

  if (currentUser?.isAdmin) {
    columns.push({
      className: 'min-w-150px',
      Header: 'Actions',
      Cell: (cell: any) => {
        return (
          <div className='d-flex flex-shrink-0'>
            <div>
              <button
                data-tooltip-id={cell.row.original.id}
                onClick={() => handleOpenEditModal(cell.row.original.id)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-3' />
              </button>
              <Tooltip id={cell.row.original.id} content='Edit' />
            </div>
          </div>
        )
      },
    })
  }

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Stablecoin Monitor'
        columns={columns}
        data={data?.stablecoins.data || []}
        isLoading={loading}
      />

      <StablecoinEditModal
        id={editID}
        data={editData}
        showStablecoinModal={showStablecoinModal}
        setShowStablecoinModal={setShowStablecoinModal}
        handleEdit={StableCoinPulseMutation}
      />
    </>
  )
}
