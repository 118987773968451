import {VenueTable} from './VenueTable'

const VenuePage = () => {
  return (
    <>
      <VenueTable />
    </>
  )
}

const VenueWrapper = () => {
  return (
    <>
      <VenuePage />
    </>
  )
}

export {VenueWrapper}
