import {QueryResult, useMutation, useQuery} from '@apollo/client'
import {Column} from 'react-table'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import {Table} from '../../components'
import {useAuth} from '../auth'
import {IReadingData} from '../../query/interface/IReading'
import {APPROVE_READING_MUTATION, DELETE_READING_MUTATION, READING_QUERY} from '../../query/Reading'

type CustomColumn = {
  className?: string
} & Column

export const ReadingTable = () => {
  const {data, loading, refetch}: QueryResult<IReadingData> = useQuery(READING_QUERY)
  const {currentUser} = useAuth()

  const [approveReading] = useMutation(APPROVE_READING_MUTATION, {
    onCompleted: () => refetch(),
  })
  const [deleteReading] = useMutation(DELETE_READING_MUTATION, {
    onCompleted: () => refetch(),
  })

  const handleApprove = (id: string) => {
    approveReading({
      variables: {id, publishedAt: new Date().toISOString()},
    })
  }

  const handleDelete = (id: string) => {
    deleteReading({
      variables: {id},
    })
  }

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      Header: 'Submission Date',
      Cell: (cell: any) => {
        return <>{new Date(cell.row.original.attributes.createdAt).toDateString()}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Title',
      Cell: (cell: any) => {
        return (
          <a
            href={process.env.REACT_APP_STRAPI_URL + cell.row.original.attributes.pdf_url}
            target='_blank'
            rel='noreferrer'
          >
            {cell.row.original.attributes.title}
          </a>
        )
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Published',
      Cell: (cell: any) => <>{(!!cell.row.original.attributes.publishedAt).toString()}</>,
    },
  ]

  if (currentUser?.isAdmin) {
    columns.push({
      className: 'min-w-150px',
      Header: 'Actions',
      Cell: (cell: any) => {
        return (
          <div className='d-flex'>
            <div>
              <button
                onClick={() => handleApprove(cell.row.original.id)}
                className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
                disabled={!!cell.row.original.attributes.publishedAt}
              >
                {!!cell.row.original.attributes.publishedAt ? 'Approved' : 'Approve'}
              </button>
            </div>
            <div>
              <button
                onClick={() => handleDelete(cell.row.original.id)}
                className='btn btn-bg-light btn-active-color-danger btn-sm me-1'
              >
                Delete
              </button>
            </div>
          </div>
        )
      },
    })
  }

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Readings'
        columns={columns}
        data={data?.readings.data || []}
        isLoading={loading}
      />
    </>
  )
}
