import {NetworkTable} from './NetworkTable'

const NetworkPage = () => {
  return (
    <>
      <NetworkTable />
    </>
  )
}

const NetworkWrapper = () => {
  return (
    <>
      <NetworkPage />
    </>
  )
}

export {NetworkWrapper}
