import clsx from 'clsx'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import TimePicker from 'react-time-picker'
import * as Yup from 'yup'
import {postEvents} from '../_request'
import {useEffect, useState} from 'react'
import {getVenues} from '../../venues/_request'
import {getNetworks} from '../../networks/_request'
import {getSponsors} from '../../sponsors/_request'
import {Loading} from '../../../../components/Loading'

const EventsSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  eventDate: Yup.number()
    .min(Date.now(), "Can't start the event today")
    .required('Date of Event is required'),
  adminName: Yup.string().required('Admin Name is required'),
  startTime: Yup.string().required('Start Time is required'),
  endTime: Yup.string().required('End Time is required'),
  venue: Yup.string().required('Venue is required'),
  network: Yup.string().required('Network is required'),
  sponsor: Yup.string().required('Sponsor is required'),
})

type Props = {
  show: boolean
  setShow: (show: boolean) => void
  setRefetch: (refetch: boolean) => void
}

export const AddEventModal = ({show, setShow, setRefetch}: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [venueData, setVenueData] = useState([])
  const [networkData, setNetworkData] = useState([])
  const [sponsorData, setSponsorData] = useState([])

  useEffect(() => {
    const asyncGetOptions = async () => {
      const resVenue = await getVenues()
      setVenueData(resVenue.data)

      const resNetwork = await getNetworks()
      setNetworkData(resNetwork.data)

      const resSponsor = await getSponsors()
      setSponsorData(resSponsor.data)

      setLoading(false)
    }

    asyncGetOptions().catch((err) => console.error(err))
  }, [])

  const formik = useFormik({
    initialValues: {
      subject: '',
      eventDate: Date.now(),
      startTime: '',
      endTime: '',
      adminName: '',
      venue: '',
      network: '',
      sponsor: '',
    },
    validationSchema: EventsSchema,
    onSubmit: async (values, {setSubmitting, resetForm, setFieldError}) => {
      try {
        setSubmitting(true)
        const now = Date.now()
        const date = new Date(values.eventDate)
        const eventStartTime: any = date.setHours(
          +values.startTime.substring(0, 2),
          +values.startTime.substring(3)
        )
        const eventEndTime: any = date.setHours(
          +values.endTime.substring(0, 2),
          +values.endTime.substring(3)
        )

        if (now >= eventStartTime) {
          setFieldError('startTime', 'Start time must be in the future')
        }

        if (eventStartTime >= eventEndTime) {
          setFieldError('startTime', 'Start time should after End time')
        }
        const payload = {
          subject_tx: values.subject,
          event_date: eventStartTime,
          event_end_date: eventEndTime,
          admin_name_tx: values.adminName,
          venue_id: +values.venue,
          network_id: +values.network,
          sponsor_id: +values.sponsor,
        }

        await postEvents(payload)
        setSubmitting(false)
        setRefetch(true)
        setShow(false)
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })
  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        setShow(false)
        formik.resetForm()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>New Event</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: 0}}>
        <div className='card'>
          <div className='card-body'>
            {loading ? (
              <Loading />
            ) : (
              <form
                className='form w-100 bg-white'
                onSubmit={formik.handleSubmit}
                noValidate
                onChange={() => formik.setStatus('')}
              >
                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}

                <div className='row gy-3 gx-5'>
                  <div className='col-12'>
                    <label className='form-label'>Subject</label>
                    <input
                      placeholder='Subject'
                      {...formik.getFieldProps('subject')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.subject && formik.errors.subject},
                        {
                          'is-valid': formik.touched.subject && !formik.errors.subject,
                        }
                      )}
                      type='text'
                      name='subject'
                      autoComplete='off'
                    />
                    {formik.touched.subject && formik.errors.subject && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.subject}</span>
                      </div>
                    )}
                  </div>

                  <div className='col-6'>
                    <label className='form-label'>Date of Event</label>
                    <DatePicker
                      selected={new Date(formik.values.eventDate)}
                      dateFormat='MMMM d, yyyy'
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.eventDate && formik.errors.eventDate},
                        {
                          'is-valid': formik.touched.eventDate && !formik.errors.eventDate,
                        }
                      )}
                      name='eventDate'
                      onChange={(date: Date) => formik.setFieldValue('eventDate', date.getTime())}
                    />
                    {formik.touched.eventDate && formik.errors.eventDate && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.eventDate}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-3'>
                    <label className='form-label'>Start Time</label>
                    <TimePicker
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.startTime && formik.errors.startTime},
                        {
                          'is-valid': formik.touched.startTime && !formik.errors.startTime,
                        }
                      )}
                      disableClock={true}
                      clearIcon={null}
                      onChange={(data) => {
                        formik.setFieldValue('startTime', data)
                      }}
                      value={formik.values.startTime}
                    />
                    {formik.touched.startTime && formik.errors.startTime && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.startTime}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-3'>
                    <label className='form-label'>End Time</label>
                    <TimePicker
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.endTime && formik.errors.endTime},
                        {
                          'is-valid': formik.touched.endTime && !formik.errors.endTime,
                        }
                      )}
                      disableClock={true}
                      clearIcon={null}
                      onChange={(data) => {
                        formik.setFieldValue('endTime', data)
                      }}
                      value={formik.values.endTime}
                    />
                    {formik.touched.endTime && formik.errors.endTime && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.endTime}</span>
                      </div>
                    )}
                  </div>

                  <div className='col-12'>
                    <label className='form-label'>Admin Name</label>
                    <input
                      placeholder='Admin Name'
                      {...formik.getFieldProps('adminName')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.adminName && formik.errors.adminName},
                        {
                          'is-valid': formik.touched.adminName && !formik.errors.adminName,
                        }
                      )}
                      type='text'
                      name='adminName'
                      autoComplete='off'
                    />
                    {formik.touched.adminName && formik.errors.adminName && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.adminName}</span>
                      </div>
                    )}
                  </div>

                  <div className='col-12'>
                    <label className='form-label'>Venue</label>
                    <select
                      className={clsx(
                        'form-select form-select-solid form-select-lg',
                        {'is-invalid': formik.touched.venue && formik.errors.venue},
                        {
                          'is-valid': formik.touched.venue && !formik.errors.venue,
                        }
                      )}
                      {...formik.getFieldProps('venue')}
                      name='venue'
                    >
                      <option value=''>Select Venue..</option>
                      {venueData.map((v: any) => (
                        <option key={v.venue_id} value={v.venue_id}>
                          {v.short_name_tx}
                        </option>
                      ))}
                    </select>
                    {formik.touched.venue && formik.errors.venue && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.venue}</span>
                      </div>
                    )}
                  </div>

                  <div className='col-12'>
                    <label className='form-label'>Network</label>
                    <select
                      className={clsx(
                        'form-select form-select-solid form-select-lg',
                        {'is-invalid': formik.touched.network && formik.errors.network},
                        {
                          'is-valid': formik.touched.network && !formik.errors.network,
                        }
                      )}
                      {...formik.getFieldProps('network')}
                      name='network'
                    >
                      <option value=''>Select Network..</option>
                      {networkData.map((v: any) => (
                        <option key={v.network_id} value={v.network_id}>
                          {v.short_name_tx}
                        </option>
                      ))}
                    </select>
                    {formik.touched.network && formik.errors.network && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.network}</span>
                      </div>
                    )}
                  </div>

                  <div className='col-12'>
                    <label className='form-label'>Sponsor</label>
                    <select
                      className={clsx(
                        'form-select form-select-solid form-select-lg',
                        {'is-invalid': formik.touched.sponsor && formik.errors.sponsor},
                        {
                          'is-valid': formik.touched.sponsor && !formik.errors.sponsor,
                        }
                      )}
                      {...formik.getFieldProps('sponsor')}
                      name='sponsor'
                    >
                      <option value=''>Select Sponsor..</option>
                      {sponsorData.map((v: any) => (
                        <option key={v.sponsor_id} value={v.sponsor_id}>
                          {v.sponsor_name_tx}
                        </option>
                      ))}
                    </select>
                    {formik.touched.sponsor && formik.errors.sponsor && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.sponsor}</span>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='p-2'>
        <button className='btn btn-secondary p-3' onClick={() => setShow(false)}>
          Close
        </button>
        <button type='submit' className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
          {formik.isSubmitting ? (
            <span data-kt-search-element='spinner'>
              <span className='spinner-border h-25px w-25px align-middle' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
