import {gql} from '@apollo/client'

export const CBDC_PULSE_QUERY = gql`
  query GetCBDCPulseShorts($limit: Int = 5) {
    cbdcMonitors(pagination: {limit: $limit}) {
      data {
        id
        attributes {
          country
          project
          status
          type
        }
      }
    }
  }
`

export const CBDC_PULSE_MUTATION = gql`
  mutation ModifyCBDCPulseShorts(
    $id: ID!
    $country: String
    $status: String
    $project: String
    $type: String
  ) {
    updateCbdcMonitor(
      id: $id
      data: {country: $country, status: $status, project: $project, type: $type}
    ) {
      data {
        id
      }
    }
  }
`

export const STABLE_COIN_PULSE_QUERY = gql`
  query GetStableCoinPulseShorts {
    stablecoins {
      data {
        id
        attributes {
          symbol
          display_price
          home_url
        }
      }
    }
  }
`

export const STABLE_COIN_PULSE_MUTATION = gql`
  mutation ModifyStablecoinPulseShorts(
    $id: ID!
    $symbol: String
    $displayPrice: Float
    $homeUrl: String
  ) {
    updateStablecoin(
      id: $id
      data: {symbol: $symbol, display_price: $displayPrice, home_url: $homeUrl}
    ) {
      data {
        id
      }
    }
  }
`
