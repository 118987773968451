import {gql} from '@apollo/client'

export const FEATURE_QUERY = gql`
  query GetFeature {
    feature {
      data {
        id
        attributes {
          story
          headline
          published_dt
          header_img_url
          writer {
            data {
              id
              attributes {
                first_name
                last_name
              }
            }
          }
          source {
            data {
              id
              attributes {
                name
                home_url
              }
            }
          }
          source_url
        }
      }
    }
  }
`

export const FEATURED_MUTATION = gql`
  mutation updateFeature(
    $story: String!
    $headline: String!
    $headerImgUrl: String!
    $writer: ID!
    $sourceUrl: String
    $source: ID
  ) {
    updateFeature(
      data: {
        story: $story
        headline: $headline
        header_img_url: $headerImgUrl
        writer: $writer
        source_url: $sourceUrl
        source: $source
      }
    ) {
      data {
        id
      }
    }
  }
`
