import {EventTable} from './EventTable'

const EventPage = () => {
  return (
    <>
      <EventTable />
    </>
  )
}

const EventWrapper = () => {
  return (
    <>
      <EventPage />
    </>
  )
}

export {EventWrapper}
