import {useMutation} from '@apollo/client'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useState} from 'react'
import {useAuth} from '../auth'
import {createReading, uploadFile} from '../../query/api/backend'
import {READING_MUTATION} from '../../query/Reading'
import {FolderUpload} from '../../helpers/constants'

const ReadingSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Title is required'),
  thumbnail_url: Yup.mixed().test('fileType', 'Unsupported File Format', (value) => {
    if (value) {
      return ['png', 'jpg', 'jpeg'].includes(value.split('.').reverse()[0])
    }
    return false
  }),
  pdf_url: Yup.mixed().test('fileType', 'Unsupported File Format, Should be PDF', (value) => {
    if (value) {
      return ['pdf'].includes(value.split('.').reverse()[0])
    }
    return false
  }),
})

export const ReadingForm = () => {
  const {auth} = useAuth()
  const [createNewReading] = useMutation(READING_MUTATION)

  const [currentThumbnail, setCurrentThumbnail] = useState(undefined)
  const handleThumbnailFile = (event: any) => {
    setCurrentThumbnail(event.target.files[0])
  }

  const [currentPDFFile, setCurrentPDFFile] = useState(undefined)
  const handlePDFFile = (event: any) => {
    setCurrentPDFFile(event.target.files[0])
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      thumbnail_url: '',
      pdf_url: '',
    },
    validationSchema: ReadingSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        if (!auth) return
        setSubmitting(true)

        const thumbnailUrl = await uploadFile(currentThumbnail, FolderUpload.READING)
        const PDFUrl = await uploadFile(currentPDFFile, FolderUpload.READING)

        createNewReading({
          variables: {
            title: values.title,
            thumbnailUrl: thumbnailUrl,
            pdfUrl: PDFUrl,
          },
        })
        resetForm()
        await createReading({
          title: values.title,
          thumbnailUrl: thumbnailUrl,
          pdfUrl: PDFUrl,
        })

        setSubmitting(false)
        setCurrentThumbnail(undefined)
        setCurrentPDFFile(undefined)
      } catch (error) {
        console.error(error)
      }
    },
  })
  return (
    <div className='card mb-5 mb-xl-10 position-relative'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>New Reading Form</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <form
          className='form w-100 bg-white'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_reding_form'
          onChange={() => formik.setStatus('')}
        >
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='row gy-3 gx-5'>
            <div className='col-12'>
              <label className='form-label'>Title</label>
              <input
                placeholder='Title'
                {...formik.getFieldProps('title')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.title && formik.errors.title},
                  {
                    'is-valid': formik.touched.title && !formik.errors.title,
                  }
                )}
                type='text'
                name='title'
                autoComplete='off'
              />
              {formik.touched.title && formik.errors.title && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              )}
            </div>

            <div className='col-12'>
              <label className='form-label'>Thumbnail</label>
              <input
                placeholder='Thumbnail'
                {...formik.getFieldProps('thumbnail_url')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.thumbnail_url && formik.errors.thumbnail_url,
                  },
                  {
                    'is-valid': formik.touched.thumbnail_url && !formik.errors.thumbnail_url,
                  }
                )}
                type='file'
                name='thumbnail_url'
                autoComplete='off'
                onChange={(e) => {
                  handleThumbnailFile(e)
                  formik.handleChange(e)
                }}
              />
              {formik.touched.thumbnail_url && formik.errors.thumbnail_url && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.thumbnail_url}</span>
                </div>
              )}
            </div>

            <div className='col-12'>
              <label className='form-label'>PDF File</label>
              <input
                placeholder='PDF File'
                {...formik.getFieldProps('pdf_url')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.pdf_url && formik.errors.pdf_url},
                  {
                    'is-valid': formik.touched.pdf_url && !formik.errors.pdf_url,
                  }
                )}
                type='file'
                name='pdf_url'
                autoComplete='off'
                onChange={(e) => {
                  handlePDFFile(e)
                  formik.handleChange(e)
                }}
              />
              {formik.touched.pdf_url && formik.errors.pdf_url && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.pdf_url}</span>
                </div>
              )}
            </div>

            <div className='d-grid mt-8'>
              <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
                {formik.isSubmitting ? (
                  <span data-kt-search-element='spinner'>
                    <span className='spinner-border h-25px w-25px align-middle' />
                  </span>
                ) : (
                  <span className='indicator-label'>Submit</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
