import {useEffect, useState} from 'react'
import {CustomColumn, Table} from '../../../components'
import {KTSVG} from '../../../../_metronic/helpers'
import {AddEventModal} from './modal/AddEventModal'
import {getEvents, approveEvent} from './_request'
import {useNavigate} from 'react-router-dom'
import { useAuth } from '../../../modules/auth'

type Props = {
  setCreated?: (created: number) => void
}
export const EventTable = ({setCreated}: Props) => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [showNewEvent, setShowNewEvent] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  const navigate = useNavigate()

  const getEventFunction = async () => {
    setLoading(true)
    const res = await getEvents()
    setData(res.data)
    if (setCreated) {
      setCreated(res.data.length)
    }
    setLoading(false)
  }

  useEffect(() => {
    getEventFunction().catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    if (refetch) {
      getEventFunction().catch((error) => console.error(error))
      setRefetch(false)
    }
  }, [refetch])

  const handleApproved = async (id: number) => {
    await approveEvent(id)
    setRefetch(true)
  }

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      accessor: 'subject_tx',
      Header: 'Subject',
    },
    {
      className: 'min-w-150px',
      Header: 'Date of Event',
      Cell: (cell: any) => {
        return <>{new Date(cell.row.original.event_date).toLocaleDateString()}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Venue',
      Cell: (cell: any) => {
        return <>{cell.row.original.venue.short_name_tx}</>
      },
    },
    {
      className: 'min-w-150px',
      accessor: 'admin_name_tx',
      Header: 'Admin Name',
    },
    {
      className: 'min-w-150px',
      Header: 'Network',
      Cell: (cell: any) => {
        return <>{cell.row.original.network.short_name_tx}</>
      },
    },
    {
      className: 'min-w-150px',
      accessor: 'approved',
      Header: 'Approved',
      Cell: (cell: any) => {
        return cell.row.original.approved ? (
          <KTSVG
            path='/media/icons/duotune/general/gen037.svg'
            className='svg-icon-2x svg-icon-success'
          />
        ) : (
          <KTSVG
            path='/media/icons/duotune/general/gen034.svg'
            className='svg-icon-2x svg-icon-danger'
          />
        )
      },
    },
  ]

  const adminColums = [
    {
      minWidth: 125,
      accessor: '',
      Header: 'Actions',
      Cell: (cell: any) => (
        <div className='d-flex flex-shrink-0'>
          <div>
            <button
              onClick={() => handleApproved(cell.row.original.event_id)}
              className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              Approve
            </button>
          </div>
        </div>
      ),
    },
  ]

  const handleRowClick = (val: any) => {
    navigate(`/events/${val.event_id}`)
  }

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Upcoming Events'
        columns={
          currentUser?.isAdmin ? [...columns, ...adminColums] : columns
        }
        data={data}
        isLoading={loading}
        onRowClick={handleRowClick}
      >
        <div className='d-flex align-items-center'>
          <button className='btn btn-primary my-2 me-2' onClick={() => setShowNewEvent(true)}>
            + New Event
          </button>
        </div>
      </Table>

      <AddEventModal show={showNewEvent} setShow={setShowNewEvent} setRefetch={setRefetch} />
    </>
  )
}

