import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const createNewItem = async (headline: string) => {
  const endponit = '/newsitem'
  return axios.post(API_URL + endponit, {headline})
}

export const createReading = async ({
  title,
  thumbnailUrl,
  pdfUrl,
}: {
  title: string
  thumbnailUrl: any
  pdfUrl: any
}) => {
  const endponit = '/reading'
  const response = axios.post(API_URL + endponit, {
    title,
    thumbnailUrl,
    pdfUrl,
  })

  return response
}

export const getSubcriber = async () => {
  const endpoint = '/subscriber'
  const response = axios.get(API_URL + endpoint)

  return response
}

export const getUsers = async () => {
  const endpoint = '/user'
  return axios.get(API_URL + endpoint)
}

export const putDisableUser = async (id: number) => {
  const endpoint = `/user/${id}/disable`
  return axios.put(API_URL + endpoint)
}

export const putEnableUser = async (id: number) => {
  const endpoint = `/user/${id}/enable`
  return axios.put(API_URL + endpoint)
}

export const putDowngradeUser = async (id: number) => {
  const endpoint = `/user/${id}/downgrade`
  return axios.put(API_URL + endpoint)
}

export const putUpgradeUser = async (id: number) => {
  const endpoint = `/user/${id}/upgrade`
  return axios.put(API_URL + endpoint)
}

export const putResetPassword = async (id: number, payload: {password: string}) => {
  const endpoint = `/user/${id}/reset-password`
  return axios.put(API_URL + endpoint, payload)
}

export const getReadingDownloaded = async () => {
  const endpoint = '/reading/downloaded'
  return axios.get(API_URL + endpoint)
}

export const uploadFile = async (file: any, folder: string) => {
  const endpoint = '/upload'
  const formData = new FormData()
  formData.append('file', file)
  formData.append('folder', folder)

  const result = await axios.post(API_URL + endpoint, formData)
  return result.data
}