import {QueryResult, useQuery} from '@apollo/client'
import {StoryTable} from '../../modules/story/StoryTable'
import {STORIES_QUERY} from '../../query/Story'
import {IStoryData} from '../../query/interface/IStory'

export const NewsItemWrapper = () => {
  const {data, loading, refetch}: QueryResult<IStoryData> = useQuery(STORIES_QUERY)

  return (
    <>
      <StoryTable data={data} loading={loading} refetch={refetch} />
    </>
  )
}
