import {useEffect, useState} from 'react'
import {CustomColumn, Table} from '../../../components'
import {AddVenueModal} from './modal/AddVenueModal'
import {getVenues} from './_request'

export const VenueTable = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showNewVenue, setShowNewVenue] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  const getVenueFunction = async () => {
    setLoading(true)
    const res = await getVenues()
    setData(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getVenueFunction().catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    if (refetch) {
      getVenueFunction().catch((error) => console.error(error))
      setRefetch(false)
    }
  }, [refetch])

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      accessor: 'short_name_tx',
      Header: 'Name',
    },
    {
      className: 'min-w-150px',
      accessor: 'address_tx',
      Header: 'Address',
    },
    {
      className: 'min-w-150px',
      accessor: 'created_at',
      Header: 'Created At',
      Cell: (cell: any) => {
        return <>{new Date(cell.row.original.created_at).toLocaleDateString()}</>
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Venues'
        columns={columns}
        data={data}
        isLoading={loading}
      >
        <div className='d-flex align-items-center'>
          <button className='btn btn-primary my-2 me-2' onClick={() => setShowNewVenue(true)}>
            + New Venue
          </button>
        </div>
      </Table>

      <AddVenueModal show={showNewVenue} setShow={setShowNewVenue} setRefetch={setRefetch} />
    </>
  )
}
