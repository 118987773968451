import {Modal} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {Loading} from '../../../../components/Loading'
import {CustomColumn, Table} from '../../../../components'
import {useNavigate, useParams} from 'react-router-dom'
import {putAssignEventSponsor} from '../_request'
import {getSponsors} from '../../sponsors/_request'

type Props = {
  show: boolean
  setShow: (show: boolean) => void
  setRefetch: (refetch: boolean) => void
}

export const AddEventSponsorModal = ({show, setShow, setRefetch}: Props) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [sponsors, setSponsors] = useState([])

  const [selectSponsors, setSelectSponsors] = useState<string[]>([])

  useEffect(() => {
    if (!id) navigate('/events')
    const asyncGetOptions = async () => {
      const res = await getSponsors()
      setSponsors(res.data)
      setLoading(false)
    }

    asyncGetOptions().catch((err) => console.error(err))
  }, [])

  const isCheckerSponsors = (id: string) => {
    return selectSponsors?.some((i) => i === id)
  }

  const handleSelectSponsors = (id: string) => {
    let arrSelect: string[] = [...selectSponsors]
    if (id && arrSelect) {
      if (!isCheckerSponsors(id)) {
        arrSelect.push(id)
      } else {
        arrSelect = arrSelect?.filter((i) => i !== id)
      }
    }
    setSelectSponsors(arrSelect)
  }

  const submit = async () => {
    if (!id) {
      return navigate('/events')
    }
    setSubmitting(true)

    const payload = {
      sponsors: selectSponsors,
    }

    await putAssignEventSponsor(id, payload)

    setSubmitting(false)
    setSelectSponsors([])
    setShow(false)
    setRefetch(true)
  }

  const columns: CustomColumn[] = [
    {
      className: 'max-w-10px',
      accessor: 'checked',
      Header: '',
      Cell: (cell: any) => (
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div
              className='form-check form-check-sm form-check-custom form-check-solid'
              onClick={(e) => e.stopPropagation()}
            >
              <input
                className='form-check-input'
                type='checkbox'
                value='1'
                data-kt-check='true'
                data-kt-check-target='.widget-9-check'
                checked={isCheckerSponsors(cell.row.original.sponsor_id)}
                onChange={() => handleSelectSponsors(cell.row.original.sponsor_id)}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      className: 'min-w-150px',
      accessor: 'sponsor_name_tx',
      Header: 'Name',
    },
  ]

  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        setShow(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Sponsors</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: 0}}>
        <div className='card'>
          <div className='card-body p-0'>
            {loading ? (
              <Loading />
            ) : (
              <Table
                className='mb-5 mb-xl-10'
                title='Sponsors'
                columns={columns}
                data={sponsors}
                isLoading={loading}
              />
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='p-2'>
        <button className='btn btn-secondary p-3' onClick={() => setShow(false)}>
          Close
        </button>
        <button type='submit' className='btn btn-primary p-3' onClick={submit}>
          {isSubmitting ? (
            <span data-kt-search-element='spinner'>
              <span className='spinner-border h-25px w-25px align-middle' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
