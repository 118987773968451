/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'MENU.CURRENCYINSIDER'})}
        to=''
        hasBullet
      >
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          to='/dashboard'
          hasBullet
        />
        <AsideMenuItem title={intl.formatMessage({id: 'MENU.MONITORS'})} to='/monitors' hasBullet />
        <AsideMenuItem title={intl.formatMessage({id: 'MENU.FEATURE'})} to='/featured' hasBullet />
        <AsideMenuItem title={intl.formatMessage({id: 'MENU.STORY'})} to='/stories' hasBullet />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.NEWSTORY'})}
          to='/new-story'
          hasBullet
        />
        <AsideMenuItem title={intl.formatMessage({id: 'MENU.READINGS'})} to='/readings' hasBullet />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.NEWREADINGS'})}
          to='/new-readings'
          hasBullet
        />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.SUBSCRIBERS'})}
          to='/subscribers'
          hasBullet
        />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.CONTRIBUTORS'})}
          to='/contributors'
          hasBullet
        />
        {currentUser?.isAdmin && (
          <AsideMenuItem
            title={intl.formatMessage({id: 'MENU.ACCOUNTS'})}
            to='/accounts'
            hasBullet
          />
        )}
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.ORGANIZATIONS'})}
          to='/organizations'
          hasBullet
        />
      </AsideMenuItemWithSub>

      {currentUser?.isAdmin && (
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.CRYPTO311'})}
          to='/crypto311'
          hasBullet
        />
      )}

      <AsideMenuItemWithSub title={intl.formatMessage({id: 'MENU.EVENTHUB'})} to='' hasBullet>
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.EVENTHUB.EVENTS'})}
          to='/eventhub/events'
          hasBullet
        />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.EVENTHUB.SPONSORS'})}
          to='/eventhub/sponsors'
          hasBullet
        />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.EVENTHUB.NETWORKS'})}
          to='/eventhub/networks'
          hasBullet
        />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.EVENTHUB.SPEAKERS'})}
          to='/eventhub/speakers'
          hasBullet
        />
        <AsideMenuItem
          title={intl.formatMessage({id: 'MENU.EVENTHUB.VENUES'})}
          to='/eventhub/venues'
          hasBullet
        />
      </AsideMenuItemWithSub>
    </>
  )
}
