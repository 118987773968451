import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'

import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {ThemeModeProvider} from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client'

setupAxios(axios)

Chart.register(...registerables)

const client = new ApolloClient({
  uri: process.env.REACT_APP_STRAPI_URL + '/graphql',
  cache: new InMemoryCache(),
  headers: {authorization: 'Bearer ' + process.env.REACT_APP_FIX_TOKEN},
})

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <ApolloProvider client={client}>
      <MetronicI18nProvider>
        <ThemeModeProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeModeProvider>
      </MetronicI18nProvider>
    </ApolloProvider>
  )
}
