import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ICBDCPulseAttributes} from '../../query/interface/ICBDCPulse'
import {useEffect} from 'react'

const CBDCEditSchema = Yup.object().shape({
  country: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Country is required'),
  project: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Project is required'),
  status: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Status is required'),
  type: Yup.string()
    .min(0, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Type is required'),
})

export const CBDCEditModal = ({
  id,
  data,
  showCBDCModal,
  setShowCBDCModal,
  handleEdit,
}: {
  id: string
  data: ICBDCPulseAttributes
  showCBDCModal: boolean
  setShowCBDCModal: any
  handleEdit: any
}) => {
  const formik = useFormik({
    initialValues: {
      country: data.country,
      project: data.project,
      status: data.status,
      type: data.type,
    },
    validationSchema: CBDCEditSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        handleEdit({
          variables: {
            id,
            country: values.country,
            project: values.project,
            status: values.status,
            type: values.type,
          },
        })
        setShowCBDCModal(false)
        resetForm()
      } catch (error: any) {
        setStatus('Error: ' + error.message)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('country', data.country)
    formik.setFieldValue('project', data.project)
    formik.setFieldValue('status', data.status)
    formik.setFieldValue('type', data.type)
  }, [showCBDCModal])

  return (
    <>
      <Modal size='lg' show={showCBDCModal} onHide={() => setShowCBDCModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit CBDC Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            onChange={() => formik.setStatus('')}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <div className='row gy-3 gx-5'>
              <div className='col-12'>
                <label className='form-label'>Country</label>
                <input
                  placeholder='Country'
                  {...formik.getFieldProps('country')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.country && formik.errors.country},
                    {
                      'is-valid': formik.touched.country && !formik.errors.country,
                    }
                  )}
                  type='text'
                  name='country'
                  autoComplete='off'
                />
                {formik.touched.country && formik.errors.country && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.country}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Project</label>
                <input
                  placeholder='Project'
                  {...formik.getFieldProps('project')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.project && formik.errors.project},
                    {
                      'is-valid': formik.touched.project && !formik.errors.project,
                    }
                  )}
                  type='text'
                  name='project'
                  autoComplete='off'
                />
                {formik.touched.project && formik.errors.project && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.project}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Status</label>
                <input
                  placeholder='Status'
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.status && formik.errors.status,
                    },
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                  type='text'
                  name='status'
                  autoComplete='off'
                />
                {formik.touched.status && formik.errors.status && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Type</label>
                <input
                  placeholder='Type'
                  {...formik.getFieldProps('type')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.type && formik.errors.type,
                    },
                    {
                      'is-valid': formik.touched.type && !formik.errors.type,
                    }
                  )}
                  type='text'
                  name='type'
                  autoComplete='off'
                />
                {formik.touched.type && formik.errors.type && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.type}</span>
                  </div>
                )}
              </div>

              {/* <div className='d-grid mt-8'>
                <button type='submit' className='btn btn-primary'>
                  <span className='indicator-label'>Upload</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div> */}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <button className='btn btn-secondary p-3' onClick={() => setShowCBDCModal(false)}>
            Close
          </button>
          <button className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
