import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {batchTransfer, getAdminBalances} from '../_request'

const NewsItemSchema = Yup.object().shape({
  amount: Yup.number().required('Amount is required'),
})

type Props = {
  show: boolean
  setShow: (show: boolean) => void
  setRefetch: (refetch: boolean) => void
  listParties: string[]
}

export const BatchSendModal = ({show, setShow, listParties, setRefetch}: Props) => {
  const [balance, setBalance] = useState<number>(0)
  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: NewsItemSchema,
    onSubmit: async (values, {setSubmitting, resetForm, setStatus}) => {
      try {
        setSubmitting(true)
        if (values.amount * listParties.length >= balance) {
          setStatus(`insufficient balance, please transfer under ${balance}`)
          return
        }
        await batchTransfer(listParties, values.amount.toString())
        setSubmitting(false)
        setRefetch(true)
        setShow(false)
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })

  useEffect(() => {
    const asyncFn = async () => {
      const res = await getAdminBalances()
      setBalance(+res.data[0].balance)
      // formik.setStatus(`Transfer under ${res.data[0].balance}`)
    }

    asyncFn().catch((err) => console.error(err))
  }, [])
  return (
    <Modal
      size='sm'
      show={show}
      onHide={() => {
        setShow(false)
        formik.resetForm()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Batch Send</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: 0}}>
        <div className='card'>
          {/* <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>News Item Form</span>
            </h3>
          </div> */}
          <div className='card-body'>
            <form
              className='form w-100 bg-white'
              onSubmit={formik.handleSubmit}
              noValidate
              onChange={() => formik.setStatus('')}
            >
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='row gy-3 gx-5'>
                <div className='col-12'>
                  <label className='form-label'>Amount</label>
                  <input
                    placeholder='Amount'
                    {...formik.getFieldProps('amount')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.amount && formik.errors.amount},
                      {
                        'is-valid': formik.touched.amount && !formik.errors.amount,
                      }
                    )}
                    type='number'
                    name='amount'
                    autoComplete='off'
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.amount}</span>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='p-2'>
        <button className='btn btn-secondary p-3' onClick={() => setShow(false)}>
          Close
        </button>
        <button type='submit' className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
          {formik.isSubmitting ? (
            <span data-kt-search-element='spinner'>
              <span className='spinner-border h-25px w-25px align-middle' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
