import {Column} from 'react-table'

import {Table} from '../../components'
import {useAuth} from '../auth'
import {useEffect} from 'react'

type CustomColumn = {
  className?: string
} & Column

type Props = {
  data: any | undefined
  loading: boolean
}

export const SubscriberTable = ({data, loading}: Props) => {
  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      accessor: 'subscriber_id',
      Header: 'ID',
    },
    // {
    //   className: 'min-w-150px',
    //   accessor: 'full_name_tx',
    //   Header: 'Full Name',
    // },
    {
      className: 'min-w-150px',
      accessor: 'email_address_tx',
      Header: 'Email address',
    },
    {
      className: 'min-w-150px',
      Header: 'Subscription Date',
      Cell: (cell: any) => {
        return <>{new Date(cell.row.original.createdAt).toDateString()}</>
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Subscribers'
        columns={columns}
        data={data || []}
        isLoading={loading}
      />
    </>
  )
}
