import {Column} from 'react-table'

import {Table} from '../../components'
import {useAuth} from '../auth'
import {useEffect, useState} from 'react'
import {
  getUsers,
  putDisableUser,
  putDowngradeUser,
  putEnableUser,
  putUpgradeUser,
} from '../../query/api/backend'
import {ResetPasswordModal} from './ResetPasswordModal'
import {useMutation, useQuery} from '@apollo/client'
import {USER_QUERY, USER_ROLE_MUTATION} from '../../query/User'

type CustomColumn = {
  className?: string
} & Column

export const AccountTable = () => {
  const [data, setData] = useState([])
  const [accountId, setResetAccountId] = useState(0)
  const [loading, setLoading] = useState(true)
  const [refecth, setReFecth] = useState(false)

  const [showResetPassword, setShowResetPassword] = useState(false)

  const [updateUserRole] = useMutation(USER_ROLE_MUTATION)
  const {data: dataStrapi, loading: loadingStrapi} = useQuery(USER_QUERY)

  useEffect(() => {
    const asyncFunction = async () => {
      const response = await getUsers()
      setData(response.data)
      setLoading(false)
      setReFecth(false)
    }

    asyncFunction().catch((err) => console.error(err))
  }, [refecth])

  const handleDisable = (id: number) => {
    putDisableUser(id).then(() => setReFecth(true))
  }

  const handleEnable = (id: number) => {
    putEnableUser(id).then(() => setReFecth(true))
  }

  const handleDowngrade = (id: number, email: string) => {
    if (loadingStrapi) return
    putDowngradeUser(id).then(() => setReFecth(true))
    const userStrapi = dataStrapi?.usersPermissionsUsers?.data.find(
      (u: any) => u.attributes.email === email
    )
    updateUserRole({
      variables: {
        id: userStrapi?.id,
        role: 1,
      },
    })
  }

  const handleUpgrade = (id: number, email: string) => {
    if (loadingStrapi) return
    putUpgradeUser(id).then(() => setReFecth(true))

    const userStrapi = dataStrapi?.usersPermissionsUsers?.data.find(
      (u: any) => u.attributes.email === email
    )
    updateUserRole({
      variables: {
        id: userStrapi?.id,
        role: 2,
      },
    })
  }

  const handleShowModal = (id: number) => {
    setShowResetPassword(true)
    setResetAccountId(id)
  }
  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      accessor: 'id',
      Header: 'ID',
    },
    {
      className: 'min-w-150px',
      accessor: 'username',
      Header: 'User name',
    },
    {
      className: 'min-w-150px',
      accessor: 'email',
      Header: 'Email address',
    },
    {
      className: 'min-w-150px',
      Header: 'Role',
      Cell: (cell: any) => {
        return cell.row.original.isAdmin ? (
          <>Admin</>
        ) : cell.row.original.isHost ? (
          <>Contributor</>
        ) : (
          <>Reader</>
        )
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Action',
      Cell: (cell: any) => {
        return (
          <div className='d-flex'>
            {!cell.row.original.isAdmin && (
              <div>
                <button
                  onClick={() =>
                    cell.row.original.isDisable
                      ? handleEnable(cell.row.original.id)
                      : handleDisable(cell.row.original.id)
                  }
                  className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  {cell.row.original.isDisable ? 'Enabled' : 'Disabled'}
                </button>

                <button
                  onClick={() =>
                    cell.row.original.isHost
                      ? handleDowngrade(cell.row.original.id, cell.row.original.email)
                      : handleUpgrade(cell.row.original.id, cell.row.original.email)
                  }
                  className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  {cell.row.original.isHost ? 'Downgrade' : 'Upgrade'}
                </button>

                <button
                  onClick={() => handleShowModal(cell.row.original.id)}
                  className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  Reset password
                </button>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Account'
        columns={columns}
        data={data || []}
        isLoading={loading}
      />

      <ResetPasswordModal
        accountId={accountId}
        showResetPassword={showResetPassword}
        setShowResetPassword={setShowResetPassword}
        setReload={setReFecth}
      />
    </>
  )
}
