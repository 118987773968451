export enum FolderUpload {
  FEATURE = 'features',
  READING = 'readings',
  STORY = 'stories',
}

export enum Format {
  VIRTUAL = 'Virtual Only',
  HYBRID = 'Hybrid',
  IN_PERSION = 'In-Person Only',
}
