import { ReadingDownloadedTable } from '../../modules/reading/ReadingDownloaded'
import {ReadingTable} from '../../modules/reading/ReadingTable'

export const ReadingWrapper = () => {
  return (
    <>
      <ReadingTable />
      <ReadingDownloadedTable />
    </>
  )
}
