import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {Nav} from 'react-bootstrap'
import {getEventDetail} from '../_request'
import {SponsorTab} from './SponsorTab'
import {SpeakerTab} from './SpeakerTab'
import {UpdateEventModal} from '../modal/UpdateEventModal'
import moment from 'moment'

export const EventDetailWrapper = () => {
  const {id} = useParams()
  const navigate = useNavigate()

  const [tableView, setTableView] = useState([] as any)
  const [data, setData] = useState<any>([])
  const [updateModalShow, setUpdateModalShow] = useState<boolean>(false)

  const [currentTab, setCurrentTab] = useState<string>('Sponsors')
  const [loading, setLoading] = useState<boolean>(true)
  const [refetch, setRefetch] = useState<boolean>(false)

  const asyncFunction = async (id: string) => {
    const result = await getEventDetail(id)
    setData(result.data)

    setTableView([
      {
        name: 'Sponsors',
        element: <SponsorTab data={result.data.sponsors} setRefetch={setRefetch} />,
      },
      {
        name: 'Speakers',
        element: <SpeakerTab data={result.data.speakers} setRefetch={setRefetch} />,
      },
    ])
    setLoading(false)
  }

  useEffect(() => {
    if (!id) return navigate('/dashboard')

    asyncFunction(id).catch((err) => {
      console.error(err)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!id) return navigate('/dashboard')

    if (refetch) {
      asyncFunction(id).catch((error) => console.error(error))
      setRefetch(false)
    }
  }, [refetch])

  return (
    <>
      {loading ? (
        <>
          <span
            className='w-100 translate-middle-y lh-0 my-auto d-flex align-items-center justify-content-center'
            data-kt-search-element='spinner'
          >
            <span className='spinner-border h-35px w-35px align-middle text-gray-400' />
          </span>
        </>
      ) : (
        <>
          <div className='card mb-5'>
            <div className='card-body p-0'>
              <div className='card mb-5 mb-xl-10' style={{height: 'calc(100% - 2.5rem)'}}>
                <div className='card-header border-0 pt-5 align-items-center'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Overview</h3>
                  </div>

                  <button className='btn btn-primary' onClick={() => setUpdateModalShow(true)}>
                    Edit
                  </button>
                </div>
                <div className='card-body p-2'>
                  <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'></div>
                    </div>

                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 mb-2'>
                              {data.subject_tx}
                            </span>
                          </div>

                          <div className='d-flex fs-6 pe-2'>
                            <div className='d-flex fw-bold flex-column'>
                              <span className='me-5 mb-2'>Admin Name</span>
                              <span className='me-5 mb-2'>Start Time</span>
                              <span className='me-5 mb-2'>End Time</span>
                              <span className='me-5 mb-2'>Network</span>
                              <span className='me-5 mb-2'>Venue</span>
                              <span className='me-5 mb-2'>Created At</span>
                            </div>
                            <div className='d-flex flex-column'>
                              <span className='me-5 mb-2'>: {data.admin_name_tx}</span>
                              <span className='me-5 mb-2'>
                                : {moment(data.event_date).format('LLL')}
                              </span>
                              <span className='me-5 mb-2'>
                                : {moment(data.event_end_date).format('LLL')}
                              </span>
                              <span className='me-5 mb-2'>: {data.network.short_name_tx}</span>
                              <span className='me-5 mb-2'>: {data.venue.short_name_tx}</span>
                              <span className='me-5 mb-2'>: {moment(data.created_at).format('LLL')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex h-55px px-9'>
                <Nav
                  activeKey={currentTab}
                  id='model-detail'
                  className='nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap text-nowrap'
                >
                  {tableView.map((view: any, idx: number) => (
                    <>
                      <Nav.Item key={idx}>
                        <Nav.Link
                          eventKey={view.name}
                          onClick={() => {
                            setCurrentTab(view.name)
                          }}
                          className='text-active-primary'
                        >
                          {view.name}
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  ))}
                </Nav>
              </div>
            </div>
          </div>

          <div className='tab-content' id='myTabContent'>
            {tableView.map((view: any, idx: number) => (
              <>
                <div
                  key={idx}
                  className={`tab-pane fade ${currentTab === view.name && 'show active'}`}
                  role='tabpanel'
                >
                  {view.element}
                </div>
              </>
            ))}
          </div>

          <UpdateEventModal
            data={data}
            show={updateModalShow}
            setShow={setUpdateModalShow}
            setRefetch={setRefetch}
          />
        </>
      )}
    </>
  )
}
