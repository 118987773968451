import {SpeakerTable} from './SpeakerTable'

const SpeakerPage = () => {
  return (
    <>
      <SpeakerTable />
    </>
  )
}

const SpeakerWrapper = () => {
  return (
    <>
      <SpeakerPage />
    </>
  )
}

export {SpeakerWrapper}
