import {useEffect, useState} from 'react'
import {SubscriberTable} from '../../modules/subcribers/SubcriberTable'
import {getSubcriber} from '../../query/api/backend'

export const SubscriberWrapper = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const asyncFunction = async () => {
      const response = await getSubcriber()
      setData(response.data)
      setLoading(false)
    }

    asyncFunction().catch((err) => console.error(err))
  }, [])
  return (
    <>
      <SubscriberTable data={data} loading={loading} />
    </>
  )
}
