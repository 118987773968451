/** @format */

import {gql} from '@apollo/client'

export const PEOPLE_QUERY = gql`
  query getPeople {
    people {
      data {
        id
        attributes {
          first_name
          last_name
        }
      }
    }
  }
`

export const CREATE_PERSON_MUTATION = gql`
  mutation createPerson($firstName: String!, $lastName: String!, $publishedAt: DateTime!) {
    createPerson(data: {first_name: $firstName, last_name: $lastName, publishedAt: $publishedAt}) {
      data {
        id
      }
    }
  }
`
