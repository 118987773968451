import {useFormik} from 'formik'
import {MainTable} from '../../modules/crypto311/MainTable'
import clsx from 'clsx'
import {postRefreshAmount} from '../../query/api/crypto311'
import { useState } from 'react'

export const Crypto311Wrapper = () => {
  const formik = useFormik({
    initialValues: {
      refreshAmount: 30,
    },
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        setSubmitting(true)

        await postRefreshAmount(values)
        setSubmitting(false)
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })

  return (
    <>
      <form
        className='card border-0 pt-5 bg-white'
        onSubmit={formik.handleSubmit}
        noValidate
        onChange={() => formik.setStatus('')}
      >
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        <div className='col-2 ms-9'>
          <label className='form-label'>Refresh Amount</label>
          <input
            placeholder='Refresh Amount'
            {...formik.getFieldProps('refreshAmount')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.refreshAmount && formik.errors.refreshAmount},
              {
                'is-valid': formik.touched.refreshAmount && !formik.errors.refreshAmount,
              }
            )}
            type='number'
            name='refreshAmount'
          />
          {formik.touched.refreshAmount && formik.errors.refreshAmount && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.refreshAmount}</span>
            </div>
          )}
          <div className='d-grid mt-8'>
            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
              {formik.isSubmitting ? (
                <span data-kt-search-element='spinner'>
                  <span className='spinner-border h-25px w-25px align-middle' />
                </span>
              ) : (
                <span className='indicator-label'>Submit</span>
              )}
            </button>
          </div>
        </div>
      </form>
      <MainTable />
    </>
  )
}
