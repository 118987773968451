import {gql} from '@apollo/client'

export const ORGANIZATION_QUERY = gql`
  query getOrganizations {
    organizations {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`
