import {gql} from '@apollo/client'

export const USER_ROLE_MUTATION = gql`
  mutation updateUserRole(
  $id: ID!
  $role: ID
) {
    updateUsersPermissionsUser(id: $id ,data: {
    	role: $role
    }) {
      data {
        id
      }
    }
  }
`

export const USER_QUERY = gql`
  query getUserWithEmail {
    usersPermissionsUsers {
      data {
        id
        attributes {
          email
        }
      }
    }
  }
`
