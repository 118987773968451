import {useMutation} from '@apollo/client'
import {Column} from 'react-table'
import {Table} from '../../components'
import {useAuth} from '../auth'
import {ICBDCPulseAttributes, ICBDCPulseData} from '../../query/interface/ICBDCPulse'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import {KTSVG} from '../../../_metronic/helpers'
import {useState} from 'react'
import {CBDCEditModal} from './CBDCEditModal'
import {CBDC_PULSE_MUTATION} from '../../query/Monitor'

type CustomColumn = {
  className?: string
} & Column

type Props = {
  data: ICBDCPulseData | undefined
  loading: boolean
  refetch: any
}

export const CBDCPulseTable = ({data, loading, refetch}: Props) => {
  const {currentUser} = useAuth()

  const [CBDCPulseMutation] = useMutation(CBDC_PULSE_MUTATION, {
    onCompleted: () => refetch(),
  })

  const [showCBDCModal, setShowCBDCModal] = useState<boolean>(false)
  const [editID, setEditID] = useState<string>('')
  const [editData, setEditData] = useState<ICBDCPulseAttributes>({
    country: '',
    project: '',
    status: '',
    type: '',
  })

  const handleOpenEditModal = (id: string) => {
    if (!data?.cbdcMonitors.data) return
    const dataEdit = data?.cbdcMonitors.data.find((d) => d.id === id)
    if (dataEdit) {
      setEditData(dataEdit?.attributes)
      setEditID(id)
      setShowCBDCModal(true)
    }
  }

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      Header: 'Country',
      Cell: (cell: any) => {
        return <>{cell.row.original.attributes.country}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Project',
      Cell: (cell: any) => {
        return <>{cell.row.original.attributes.project}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Status',
      Cell: (cell: any) => {
        return <>{cell.row.original.attributes.status}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Type',
      Cell: (cell: any) => {
        return <>{cell.row.original.attributes.type}</>
      },
    },
  ]

  if (currentUser?.isAdmin) {
    columns.push({
      className: 'min-w-150px',
      Header: 'Actions',
      Cell: (cell: any) => {
        return (
          <div className='d-flex flex-shrink-0'>
            <div>
              <button
                data-tooltip-id={cell.row.original.id}
                onClick={() => handleOpenEditModal(cell.row.original.id)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-3' />
              </button>
              <Tooltip id={cell.row.original.id} content='Edit' />
            </div>
          </div>
        )
      },
    })
  }

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='CBDC Monitor'
        columns={columns}
        data={data?.cbdcMonitors.data || []}
        isLoading={loading}
      />

      <CBDCEditModal
        id={editID}
        data={editData}
        showCBDCModal={showCBDCModal}
        setShowCBDCModal={setShowCBDCModal}
        handleEdit={CBDCPulseMutation}
      />
    </>
  )
}
