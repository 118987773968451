import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {SandboxWrapper} from '../pages/sandbox'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {NewsItemWrapper} from '../pages/news-item/NewsItemWrapper'
import {ReadingWrapper} from '../pages/reading/ReadingWrapper'
import {SubscriberWrapper} from '../pages/subcriber/SubcriberWrapper'
import {MonitorWrapper} from '../pages/monitor/MonitorWrapper'
import {FeatureWrapper} from '../pages/feature/FuturedWrapper'
import {AccountsWrapper} from '../pages/accounts/AccountWrapper'
import {NewStoryWrapper} from '../pages/new-story/NewStoryWrapper'
import {ContributorWrapper} from '../pages/contributor/ContributorWrapper'
import {NewReadingWrapper} from '../pages/new-reading/NewReadingWrapper'
import {EditStoryWrapper} from '../pages/news-item/EditStoryWrapper'
import {OrganizationWrapper} from '../pages/organizations/organizationWrapper'
import {Crypto311Wrapper} from '../pages/crypto311/Crypto311Wrapper'
import { EventWrapper } from '../pages/eventhub/events/EventWrapper'
import { EventDetailWrapper } from '../pages/eventhub/events/detail/EventDetailPage'
import { SponsorWrapper } from '../pages/eventhub/sponsors/SponsorWrapper'
import { NetworkWrapper } from '../pages/eventhub/networks/NetworkWrapper'
import { SpeakerWrapper } from '../pages/eventhub/speakers/SpeakerWrapper'
import { VenueWrapper } from '../pages/eventhub/venues/VenueWrapper'
import { PartiesWrapper } from '../pages/eventhub/parties/PartiesWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='stories' element={<NewsItemWrapper />} />
        <Route path='story/:id' element={<EditStoryWrapper />} />
        <Route path='monitors' element={<MonitorWrapper />} />
        <Route path='featured' element={<FeatureWrapper />} />
        <Route path='readings' element={<ReadingWrapper />} />
        <Route path='new-readings' element={<NewReadingWrapper />} />
        <Route path='subscribers' element={<SubscriberWrapper />} />
        <Route path='accounts' element={<AccountsWrapper />} />
        <Route path='new-story' element={<NewStoryWrapper />} />
        <Route path='sandbox' element={<SandboxWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='contributors' element={<ContributorWrapper />} />
        <Route path='organizations' element={<OrganizationWrapper />} />
        <Route path='crypto311' element={<Crypto311Wrapper />} />


        {/* eventhub */}
        <Route path='eventhub/events' element={<EventWrapper />} />
        <Route path='eventhub/events/:id' element={<EventDetailWrapper />} />
        <Route path='eventhub/sponsors' element={<SponsorWrapper />} />
        <Route path='eventhub/networks' element={<NetworkWrapper />} />
        <Route path='eventhub/speakers' element={<SpeakerWrapper />} />
        <Route path='eventhub/venues' element={<VenueWrapper />} />
        <Route path='eventhub/parties' element={<PartiesWrapper />} />


        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
