import {QueryResult, useQuery} from '@apollo/client'
import {StoryTable} from '../../modules/story/StoryTable'
import {STORIES_QUERY} from '../../query/Story'
import {IStoryData} from '../../query/interface/IStory'
import {useEffect, useState} from 'react'
import {getUsers} from '../../query/api/backend'
import {ContributorTable} from '../../modules/contributors/ContributorTable'
import {AdminTable} from '../../modules/contributors/AdminTable'

export const ContributorWrapper = () => {
  const [admins, setAdmins] = useState<any>([])
  const [contributors, setContributors] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const asyncFunction = async () => {
      const {data} = await getUsers()
      const adminUsers = data.filter((u: any) => u.isAdmin)
      setAdmins(adminUsers)

      const contributorUsers = data.filter((u: any) => !u.isAdmin)
      setContributors(contributorUsers)

      setLoading(false)
    }

    asyncFunction().catch((e) => console.log(e))
  }, [])

  return (
    <>
      <AdminTable data={admins} loading={loading} />
      <ContributorTable data={contributors} loading={loading} />
    </>
  )
}
