import {useEffect, useState} from 'react'
import {CustomColumn, Table} from '../../../components'
import {dataDummy, dummyData} from './dummy'
import {KTSVG, simpleUUID} from '../../../../_metronic/helpers'
import {ClipboardCopy} from '../../../components/ClipboardCopy'
import {AddPartiesModal} from './modal/AddPartiesModal'
import {Dropdown} from 'react-bootstrap'
import {BatchSendModal} from './modal/BatchSendModal'
import {getParties} from './_request'

export const PartiesTable = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showNewParties, setShowNewParties] = useState<boolean>(false)
  const [showBatchSend, setShowBatchSend] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)
  const [data, setData] = useState<dataDummy[]>([])

  const [selectParties, setSelectParties] = useState<string[]>([])

  const getPartiesFunction = async () => {
    setLoading(true)
    const res = await getParties()
    setData(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPartiesFunction().catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    if (refetch) {
      getPartiesFunction().catch((error) => console.error(error))
      setRefetch(false)
    }
  }, [refetch])

  const isCheckerParties = (adr: string) => {
    return selectParties?.some((i) => i === adr)
  }

  const handleSelectParties = (adr: string) => {
    let arrSelect: string[] = [...selectParties]
    if (adr && arrSelect) {
      if (!isCheckerParties(adr)) {
        arrSelect.push(adr)
      } else {
        arrSelect = arrSelect?.filter((i) => i !== adr)
      }
    }
    setSelectParties(arrSelect)
  }

  const handleDeactiveParties = () => {
    const newData = data.map((item, idx) => {
      const isPartySelected = selectParties.some((x) => x === item.id)
      if (isPartySelected) {
        item.status = false
      }

      return item
    })
    setData(newData)
    setSelectParties([])
  }

  const columns: CustomColumn[] = [
    {
      className: 'max-w-10px',
      accessor: 'checked',
      Header: '',
      Cell: (cell: any) => (
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div
              className='form-check form-check-sm form-check-custom form-check-solid'
              onClick={(e) => e.stopPropagation()}
            >
              <input
                className='form-check-input'
                type='checkbox'
                value='1'
                data-kt-check='true'
                data-kt-check-target='.widget-9-check'
                checked={isCheckerParties(cell.row.original.pk)}
                onChange={() => handleSelectParties(cell.row.original.pk)}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      className: 'min-w-150px',
      accessor: 'pk',
      Header: 'Address',
      Cell: (cell: any) => (
        <div className='d-flex'>
          {simpleUUID(cell.row.original.pk)} <ClipboardCopy text={cell.row.original.pk} />
        </div>
      ),
    },
    {
      className: 'min-w-150px',
      accessor: 'name',
      Header: 'Name',
    },
    {
      className: 'min-w-150px',
      accessor: 'email',
      Header: 'Email',
    },
    {
      className: 'min-w-150px',
      Header: 'Balance',
      Cell: (cell: any) => {
        return <>{+cell.row.original.balances[0].balance || 'x'}</>
      },
    },
    {
      className: 'min-w-150px',
      accessor: 'status',
      Header: 'Status',
      Cell: (cell: any) => {
        return cell.row.original.status ? (
          <KTSVG
            path='/media/icons/duotune/general/gen037.svg'
            className='svg-icon-2x svg-icon-success'
          />
        ) : (
          <KTSVG
            path='/media/icons/duotune/general/gen034.svg'
            className='svg-icon-2x svg-icon-danger'
          />
        )
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Parties'
        columns={columns}
        data={data}
        isLoading={loading}
      >
        <div className='d-flex align-items-center'>
          <button className='btn btn-primary my-2 me-2' onClick={() => setShowNewParties(true)}>
            + Add Party
          </button>
          <Dropdown>
            <Dropdown.Toggle>Action</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setShowBatchSend(true)}>Batch Send</Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeactiveParties()}>Deactivate</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Table>

      <AddPartiesModal show={showNewParties} setShow={setShowNewParties} setRefetch={setRefetch} />
      <BatchSendModal show={showBatchSend} setShow={setShowBatchSend} listParties={selectParties} setRefetch={setRefetch}/>
    </>
  )
}
