import {Column} from 'react-table'
import 'react-tooltip/dist/react-tooltip.css'

import {Table} from '../../components'
import {useAuth} from '../auth'
import {useEffect, useState} from 'react'
import {PINNED_READING_QUERY, READING_QUERY} from '../../query/Reading'
import {getReadingDownloaded} from '../../query/api/backend'
import {useLazyQuery} from '@apollo/client'

type CustomColumn = {
  className?: string
} & Column

export const ReadingDownloadedTable = () => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const {currentUser} = useAuth()

  const [getPinnedReadings] = useLazyQuery(PINNED_READING_QUERY, {
    onCompleted(dataQuery: any) {
      const result = data.map((read: any) => ({
        readingId: read?.readingId,
        username: read?.username,
        title:
          +dataQuery?.pinnedReading?.data?.id === read.readingId
            ? dataQuery?.pinnedReading?.data?.attributes.title
            : read.title,
      }))

      console.log(result)
      setData(result)
      setLoading(false)
    },
  })

  const [getReadings] = useLazyQuery(READING_QUERY, {
    variables: {
      limit: 999,
    },
    onCompleted(dataQuery: any) {
      const result = data.map((read: any, idx: number) => ({
        readingId: read?.readingId,
        username: read?.user_id?.username,
        title: dataQuery?.readings?.data.find((book: any) => +book.id === read.readingId)
          ?.attributes.title,
      }))

      setData(result)
      getPinnedReadings()
    },
  })

  useEffect(() => {
    const asyncFunction = async () => {
      const res = await getReadingDownloaded()
      setData(res.data)
      getReadings()
    }

    asyncFunction().catch((err) => console.log(err))
  }, [])

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      Header: 'Title',
      Cell: (cell: any) => {
        return <>{cell.row.original.title}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'User',
      Cell: (cell: any) => {
        return <>{cell.row.original.username}</>
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Reading Downloaded'
        columns={columns}
        data={data}
        isLoading={loading}
      />
    </>
  )
}
