import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_EVENTHUB_URL

export const getEvents = async (): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/events`
  return axios(endpoint)
}

export const getEventDetail = async (id: string): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/events/${id}`
  return axios(endpoint)
}

export const postEvents = async (payload: any): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/events`
  return axios.post(endpoint, payload)
}

export const putEvent = async (id: string, payload: any): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/events/${id}`
  return axios.put(endpoint, payload)
}

export const approveEvent = async (id: number): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/events/${id}/approve`
  return axios.put(endpoint)
}

export const putAssignEventSpeaker = async (
  id: string,
  payload: any
): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/events/${id}/assign/speakers`
  return axios.put(endpoint, payload)
}

export const putAssignEventSponsor = async (
  id: string,
  payload: any
): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/events/${id}/assign/sponsors`
  return axios.put(endpoint, payload)
}
