/** @format */

import {useState} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import CopyToClipboard from 'react-copy-to-clipboard'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

type Props = {
  text: string
  width?: string | number
}

export const ClipboardCopy: React.FC<Props> = ({text, width = 17}) => {
  const [isCopy, setIsCopy] = useState(false)

  return (
    <>
      {text && text !== '' ? (
        <>
          <CopyToClipboard
            onCopy={() => {
              setIsCopy(true)
              setTimeout(() => {
                setIsCopy(false)
              }, 1500)
            }}
            text={text}
          >
            <div
              data-tooltip-id={text}
              style={{position: 'relative'}}
              className='copy-link cursor-pointer mx-2'
              onClick={(e) => e.stopPropagation()}
            >
              <KTSVG path='/media/svg/general/copy-icon.svg' />
            </div>
          </CopyToClipboard>
          <Tooltip id={text} content='Copied' isOpen={isCopy} />
        </>
      ) : (
        ''
      )}
    </>
  )
}
