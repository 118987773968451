/** @format */

import {gql} from '@apollo/client'

export const STORIES_QUERY = gql`
  query GetAllStoriesDashBoard {
    stories(publicationState: PREVIEW, sort: "createdAt:desc") {
      data {
        id
        attributes {
          headline
          publishedAt
          createdAt
        }
      }
    }
  }
`

export const STORY_MUTATION = gql`
  mutation createStrory(
    $story: String!
    $headline: String!
    $headerImgUrl: String!
    $writer: ID!
    $sourceUrl: String
    $source: ID
  ) {
    createStory(
      data: {
        story: $story
        headline: $headline
        header_img_url: $headerImgUrl
        writer: $writer
        source_url: $sourceUrl
        source: $source
      }
    ) {
      data {
        id
      }
    }
  }
`

export const APPROVE_STORY_MUTATION = gql`
  mutation approveStory($id: ID!, $publishedAt: DateTime) {
    updateStory(id: $id, data: {publishedAt: $publishedAt, published_dt: $publishedAt}) {
      data {
        id
      }
    }
  }
`
export const STORY_QUERY = gql`
  query getStory($id: ID!) {
    story(id: $id) {
      data {
        id
        attributes {
          story
          headline
          published_dt
          header_img_url
          writer {
            data {
              id
              attributes {
                first_name
                last_name
              }
            }
          }
          source {
            data {
              id
              attributes {
                name
                home_url
              }
            }
          }
          source_url
        }
      }
    }
  }
`

export const EDIT_STORY_MUTATION = gql`
  mutation updateStory(
    $id: ID!
    $story: String!
    $headline: String!
    $headerImgUrl: String!
    $writer: ID!
    $sourceUrl: String
    $source: ID
  ) {
    updateStory(
      id: $id
      data: {
        story: $story
        headline: $headline
        header_img_url: $headerImgUrl
        writer: $writer
        source_url: $sourceUrl
        source: $source
      }
    ) {
      data {
        id
      }
    }
  }
`
