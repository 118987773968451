import {Column} from 'react-table'
import {Table} from '../../components'
import {useAuth} from '../auth'

type CustomColumn = {
  className?: string
} & Column

type Props = {
  data: any
  loading: boolean
}

export const ContributorTable = ({data, loading}: Props) => {
  const {currentUser} = useAuth()

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      Header: 'ID',
      Cell: (cell: any) => {
        return <>{cell.row.original.id}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Username',
      Cell: (cell: any) => {
        return <>{cell.row.original.username}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Email',
      Cell: (cell: any) => {
        return <>{cell.row.original.email}</>
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Contributors'
        columns={columns}
        data={data}
        isLoading={loading}
      />
    </>
  )
}
