import {PartiesTable} from './PartiesTable'

const PartiesPage = () => {
  return (
    <>
      <PartiesTable />
    </>
  )
}

const PartiesWrapper = () => {
  return (
    <>
      <PartiesPage />
    </>
  )
}

export {PartiesWrapper}
