import {QueryResult, useMutation, useQuery} from '@apollo/client'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {PEOPLE_QUERY} from '../../query/People'
import {STORY_MUTATION} from '../../query/Story'
import {IPersonData} from '../../query/interface/IPeople'
import {useRef, useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'
import {useAuth} from '../auth'
import {createNewItem, uploadFile} from '../../query/api/backend'
import {SOURCE_QUERY} from '../../query/Source'
import {ISourceData} from '../../query/interface/ISource'
import { FolderUpload } from '../../helpers/constants'

const StorySchema = Yup.object().shape({
  headline: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Headline is required'),
  writer: Yup.string().required('Writer is required'),
  file: Yup.mixed().test('fileType', 'Unsupported File Format', (value) => {
    if (value) {
      return ['png', 'jpg', 'jpeg'].includes(value.split('.').reverse()[0])
    }
    return false
  }),
  story: Yup.string().required('Story is required'),
  source: Yup.string(),
  sourceURL: Yup.string(),
})

export const StoryForm = () => {
  const {auth} = useAuth()
  const {data: peopleData}: QueryResult<IPersonData> = useQuery(PEOPLE_QUERY)

  const {data: sourceData}: QueryResult<ISourceData> = useQuery(SOURCE_QUERY)

  const [createStrory] = useMutation(STORY_MUTATION)

  const [currentFile, setCurrentFile] = useState(undefined)

  const handleFile = (event: any) => {
    setCurrentFile(event.target.files[0])
  }

  const editorRef = useRef<any>(null)

  const formik = useFormik({
    initialValues: {
      headline: '',
      source: '',
      sourceURL: '',
      writer: '',
      file: '',
      story: '',
    },
    validationSchema: StorySchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        if (!auth) return
        setSubmitting(true)

        const storyUrl = await uploadFile(currentFile, FolderUpload.STORY)

        createStrory({
          variables: {
            headline: values.headline,
            story: values.story,
            headerImgUrl: storyUrl,
            writer: values.writer,
            sourceUrl: values.sourceURL,
            source: values.source,
          },
        })

        await createNewItem(values.headline)
        setSubmitting(false)
        setCurrentFile(undefined)
        editorRef.current.setContent('')
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })
  return (
    <div className='card mb-5 mb-xl-10 position-relative'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>New Story Form</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <form
          className='form w-100 bg-white'
          onSubmit={formik.handleSubmit}
          noValidate
          onChange={() => formik.setStatus('')}
        >
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='row gy-3 gx-5'>
            <div className='col-12'>
              <label className='form-label'>Headline</label>
              <input
                placeholder='Headline'
                {...formik.getFieldProps('headline')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.headline && formik.errors.headline},
                  {
                    'is-valid': formik.touched.headline && !formik.errors.headline,
                  }
                )}
                type='text'
                name='headline'
                autoComplete='off'
              />
              {formik.touched.headline && formik.errors.headline && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.headline}</span>
                </div>
              )}
            </div>

            <div className='col-12'>
              <label className='form-label'>Story</label>
              <Editor
                onChange={() => (formik.values.story = editorRef.current.getContent())}
                tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
                onInit={(_, editor) => (editorRef.current = editor)}
                initialValue='<p>This is the initial content of the editor.</p>'
                init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'preview',
                    'help',
                    'wordcount',
                  ],
                  toolbar:
                    'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-size:14px }',
                }}
              />
              {formik.touched.story && formik.errors.story && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.story}</span>
                </div>
              )}
            </div>

            <div className='col-12'>
              <label className='form-label'>Story Image</label>
              <input
                placeholder='Story Image'
                {...formik.getFieldProps('file')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.file && formik.errors.file},
                  {
                    'is-valid': formik.touched.file && !formik.errors.file,
                  }
                )}
                type='file'
                name='file'
                autoComplete='off'
                onChange={(e) => {
                  handleFile(e)
                  formik.handleChange(e)
                }}
              />
              {formik.touched.file && formik.errors.file && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.file}</span>
                </div>
              )}
            </div>

            {/* <div className='col-12'>
              <label className='form-label'>Category</label>

              <div>
                <select
                  className={clsx(
                    'form-select form-select-solid form-select-lg',
                    {'is-invalid': formik.touched.category && formik.errors.category},
                    {
                      'is-valid': formik.touched.category && !formik.errors.category,
                    }
                  )}
                  {...formik.getFieldProps('category')}
                  name='category'
                >
                  <option value=''>Select Category..</option>
                  {catogoriesData?.categories.data.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.attributes.category}
                    </option>
                  ))}
                </select>
                {formik.touched.category && formik.errors.category && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.category}</span>
                  </div>
                )}
              </div>
            </div> */}

            <div className='col-12'>
              <label className='form-label'>Contributor</label>

              <div>
                <select
                  className={clsx(
                    'form-select form-select-solid form-select-lg',
                    {'is-invalid': formik.touched.writer && formik.errors.writer},
                    {
                      'is-valid': formik.touched.writer && !formik.errors.writer,
                    }
                  )}
                  {...formik.getFieldProps('writer')}
                  name='writer'
                >
                  <option value=''>Select Contributor...</option>
                  {peopleData?.people.data.map((c) => (
                    <option key={c.id} value={c.id}>
                      {`${c.attributes.first_name} ${c.attributes.last_name}`}
                    </option>
                  ))}
                </select>
                {formik.touched.writer && formik.errors.writer && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.writer}</span>
                  </div>
                )}
              </div>
            </div>

            <div className='col-12'>
              <label className='form-label'>Source</label>

              <div>
                <select
                  className={clsx(
                    'form-select form-select-solid form-select-lg',
                    {'is-invalid': formik.touched.source && formik.errors.source},
                    {
                      'is-valid': formik.touched.source && !formik.errors.source,
                    }
                  )}
                  {...formik.getFieldProps('source')}
                  name='source'
                >
                  <option value=''>Select Source...</option>
                  {sourceData?.sources.data.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.attributes.name}
                    </option>
                  ))}
                </select>
                {formik.touched.source && formik.errors.source && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.source}</span>
                  </div>
                )}
              </div>
            </div>

            <div className='col-12'>
              <label className='form-label'>Source URL</label>
              <input
                placeholder='Source URL'
                {...formik.getFieldProps('sourceURL')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.sourceURL && formik.errors.sourceURL},
                  {
                    'is-valid': formik.touched.sourceURL && !formik.errors.sourceURL,
                  }
                )}
                type='text'
                name='sourceURL'
                autoComplete='off'
              />
              {formik.touched.sourceURL && formik.errors.sourceURL && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.sourceURL}</span>
                </div>
              )}
            </div>

            <div className='d-grid mt-8'>
              <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
                {formik.isSubmitting ? (
                  <span data-kt-search-element='spinner'>
                    <span className='spinner-border h-25px w-25px align-middle' />
                  </span>
                ) : (
                  <span className='indicator-label'>Submit</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
