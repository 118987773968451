import clsx from 'clsx'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import TimePicker from 'react-time-picker'
import * as Yup from 'yup'
import {putEvent} from '../_request'
import {useEffect, useState} from 'react'
import {Loading} from '../../../../components/Loading'
import {useNavigate, useParams} from 'react-router-dom'
import {EventModel} from '../../../../query/interface/IEventHub'

const EventsSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  eventDate: Yup.number()
    .min(Date.now(), "Can't start the event today")
    .required('Date of Event is required'),
  startTime: Yup.string().required('Start Time is required'),
  endTime: Yup.string().required('End Time is required'),
})

type Props = {
  data: EventModel
  show: boolean
  setShow: (show: boolean) => void
  setRefetch: (refetch: boolean) => void
}

export const UpdateEventModal = ({data, show, setShow, setRefetch}: Props) => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)

  const formik = useFormik({
    initialValues: {
      subject: data.subject_tx,
      eventDate: new Date(data.event_date).getTime(),
      startTime: `${new Date(data.event_date).getHours().toString().padStart(2, '0')}:${new Date(
        data.event_date
      )
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
      endTime: `${new Date(data.event_end_date).getHours().toString().padStart(2, '0')}:${new Date(
        data.event_end_date
      )
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
    },
    onSubmit: async (values, {setSubmitting, resetForm, setFieldError}) => {
      try {
        if (!id) {
          return navigate('/events')
        }

        setSubmitting(true)
        const now = Date.now()
        const date = new Date(values.eventDate)
        const eventStartTime: any = date.setHours(
          +values.startTime.substring(0, 2),
          +values.startTime.substring(3)
        )
        const eventEndTime: any = date.setHours(
          +values.endTime.substring(0, 2),
          +values.endTime.substring(3)
        )

        if(now >= eventStartTime) {
          setFieldError('startTime', 'Start time must be in the future')
        }

        if (eventStartTime >= eventEndTime) {
          setFieldError('startTime', 'Start time should after End time')
        }

        const payload = {
          subject_tx: values.subject,
          event_date: eventStartTime,
          event_end_date: eventEndTime,
        }

        await putEvent(id, payload)
        setSubmitting(false)
        setRefetch(true)
        setShow(false)
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })
  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        setShow(false)
        formik.resetForm()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>New Event</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: 0}}>
        <div className='card'>
          <div className='card-body'>
            <form
              className='form w-100 bg-white'
              onSubmit={formik.handleSubmit}
              noValidate
              onChange={() => formik.setStatus('')}
            >
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='row gy-3 gx-5'>
                <div className='col-12'>
                  <label className='form-label'>Subject</label>
                  <input
                    placeholder='Subject'
                    {...formik.getFieldProps('subject')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.subject && formik.errors.subject},
                      {
                        'is-valid': formik.touched.subject && !formik.errors.subject,
                      }
                    )}
                    type='text'
                    name='subject'
                    autoComplete='off'
                  />
                  {formik.touched.subject && formik.errors.subject && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.subject}</span>
                    </div>
                  )}
                </div>

                <div className='col-6'>
                  <label className='form-label'>Date of Event</label>
                  <DatePicker
                    selected={new Date(formik.values.eventDate)}
                    dateFormat='MMMM d, yyyy'
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.eventDate && formik.errors.eventDate},
                      {
                        'is-valid': formik.touched.eventDate && !formik.errors.eventDate,
                      }
                    )}
                    name='eventDate'
                    onChange={(date: Date) => formik.setFieldValue('eventDate', date.getTime())}
                  />
                  {formik.touched.eventDate && formik.errors.eventDate && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.eventDate}</span>
                    </div>
                  )}
                </div>
                <div className='col-3'>
                  <label className='form-label'>Start Time</label>
                  <TimePicker
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.startTime && formik.errors.startTime},
                      {
                        'is-valid': formik.touched.startTime && !formik.errors.startTime,
                      }
                    )}
                    disableClock={true}
                    clearIcon={null}
                    onChange={(data) => {
                      formik.setFieldValue('startTime', data)
                    }}
                    value={formik.values.startTime}
                  />
                  {formik.touched.startTime && formik.errors.startTime && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.startTime}</span>
                    </div>
                  )}
                </div>
                <div className='col-3'>
                  <label className='form-label'>End Time</label>
                  <TimePicker
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.endTime && formik.errors.endTime},
                      {
                        'is-valid': formik.touched.endTime && !formik.errors.endTime,
                      }
                    )}
                    disableClock={true}
                    clearIcon={null}
                    onChange={(data) => {
                      formik.setFieldValue('endTime', data)
                    }}
                    value={formik.values.endTime}
                  />
                  {formik.touched.endTime && formik.errors.endTime && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.endTime}</span>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='p-2'>
        <button className='btn btn-secondary p-3' onClick={() => setShow(false)}>
          Close
        </button>
        <button type='submit' className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
          {formik.isSubmitting ? (
            <span data-kt-search-element='spinner'>
              <span className='spinner-border h-25px w-25px align-middle' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
