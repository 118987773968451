import {useEffect, useState} from 'react'
import {CustomColumn, Table} from '../../../components'
import {AddNetworkModal} from './modal/AddNetworkModal'
import {getNetworks} from './_request'

export const NetworkTable = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showNewNetwork, setShowNewNetwork] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  const getNetworkFunction = async () => {
    setLoading(true)
    const res = await getNetworks()
    setData(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getNetworkFunction().catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    if (refetch) {
      getNetworkFunction().catch((error) => console.error(error))
      setRefetch(false)
    }
  }, [refetch])

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      accessor: 'short_name_tx',
      Header: 'Name',
    },
    {
      className: 'min-w-150px',
      accessor: 'created_at',
      Header: 'Created At',
      Cell: (cell: any) => {
        return <>{new Date(cell.row.original.created_at).toLocaleDateString()}</>
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Networks'
        columns={columns}
        data={data}
        isLoading={loading}
      >
        <div className='d-flex align-items-center'>
          <button className='btn btn-primary my-2 me-2' onClick={() => setShowNewNetwork(true)}>
            + New Network
          </button>
        </div>
      </Table>

      <AddNetworkModal show={showNewNetwork} setShow={setShowNewNetwork} setRefetch={setRefetch} />
    </>
  )
}
