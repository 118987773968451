import {useMutation} from '@apollo/client'
import {Column} from 'react-table'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import {APPROVE_STORY_MUTATION} from '../../query/Story'
import {Table} from '../../components'
import {useAuth} from '../auth'
import {IStoryData} from '../../query/interface/IStory'
import {useNavigate} from 'react-router-dom'

type CustomColumn = {
  className?: string
} & Column

type Props = {
  data: IStoryData | undefined
  loading: boolean
  refetch: any
}

export const StoryTable = ({data, loading, refetch}: Props) => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const [approveNewsItem] = useMutation(APPROVE_STORY_MUTATION, {
    onCompleted: () => refetch(),
  })

  const handleApprove = (id: string) => {
    approveNewsItem({
      variables: {id, publishedAt: new Date().toISOString()},
    })
  }

  const handleEditStory = (id: string) => {
    navigate('/story/' + id)
  }

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      Header: 'Submission Date',
      Cell: (cell: any) => {
        return <>{new Date(cell.row.original.attributes.createdAt).toDateString()}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Headline',
      Cell: (cell: any) => cell.row.original.attributes.headline,
    },
    {
      className: 'min-w-150px',
      Header: 'Published',
      Cell: (cell: any) => (cell.row.original.attributes.publishedAt ? 'true' : 'false'),
    },
  ]

  if (currentUser?.isAdmin) {
    columns.push({
      className: 'min-w-150px',
      Header: 'Actions',
      Cell: (cell: any) => {
        return (
          <div>
            <button
              onClick={() => handleEditStory(cell.row.original.id)}
              className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              Edit
            </button>
            <button
              onClick={() => handleApprove(cell.row.original.id)}
              className='btn btn-bg-light btn-active-color-primary btn-sm me-1'
              disabled={!!cell.row.original.attributes.publishedAt}
            >
              Approve
            </button>
          </div>
        )
      },
    })
  }

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Stories'
        columns={columns}
        data={data?.stories.data || []}
        isLoading={loading}
      ></Table>
    </>
  )
}
