import {useState} from 'react'
import {CustomColumn, Table} from '../../../../components'
import {AddEventSpeakerModal} from '../modal/AddEventSpeakerModal'

type Props = {
  data: any
  setRefetch: (refetch: boolean) => void
}

export const SpeakerTab = ({data, setRefetch}: Props) => {
  const [showEventSpeaker, setShowEventSpeaker] = useState<boolean>(false)

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      accessor: 'name',
      Header: 'Name',
    },
    {
      className: 'min-w-150px',
      accessor: 'title_tx',
      Header: 'Title',
    },
    {
      className: 'min-w-150px',
      accessor: 'linkedin_url',
      Header: 'Linkedin URL',
    },
    {
      className: 'min-w-150px',
      accessor: 'bio_tx',
      Header: 'Bio',
    },
    {
      className: 'min-w-150px',
      accessor: 'created_at',
      Header: 'Created At',
      Cell: (cell: any) => {
        return <>{new Date(cell.row.original.created_at).toLocaleDateString()}</>
      },
    },
  ]

  return (
    <>
      <div className='card mb-5 mb-xl-10' style={{height: 'calc(100% - 2.5rem)'}}>
        <div className='card-body p-2'>
          <Table
            className='mb-5 mb-xl-10'
            title='Speakers'
            columns={columns}
            data={data}
            isLoading={!data}
          >
            <div className='d-flex align-items-center'>
              <button
                className='btn btn-primary my-2 me-2'
                onClick={() => setShowEventSpeaker(true)}
              >
                Assign Speaker
              </button>
            </div>
          </Table>
        </div>
      </div>

      <AddEventSpeakerModal
        show={showEventSpeaker}
        setShow={setShowEventSpeaker}
        setRefetch={setRefetch}
      />
    </>
  )
}
