import {SponsorTable} from './SponsorTable'

const SponsorPage = () => {
  return (
    <>
      <SponsorTable />
    </>
  )
}

const SponsorWrapper = () => {
  return (
    <>
      <SponsorPage />
    </>
  )
}

export {SponsorWrapper}
