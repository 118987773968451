export const Loading = () => {
    return (
      <>
        <span
          className='w-100 translate-middle-y lh-0 my-auto d-flex align-items-center justify-content-center'
          data-kt-search-element='spinner'
        >
          <span className='spinner-border h-35px w-35px align-middle text-gray-400' />
        </span>
      </>
    )
  }
  