/** @format */

import {gql} from '@apollo/client'

export const READING_QUERY = gql`
  query GetReadingTableDashboard($limit: Int = 10) {
    readings(pagination: {limit: $limit}, sort: "createdAt:desc", publicationState: PREVIEW) {
      data {
        id
        attributes {
          thumbnail_url
          pdf_url
          title
          publishedAt
          createdAt
        }
      }
    }
  }
`

export const READING_MUTATION = gql`
  mutation createNewReading($title: String!, $thumbnailUrl: String!, $pdfUrl: String!) {
    createReading(data: {title: $title, thumbnail_url: $thumbnailUrl, pdf_url: $pdfUrl}) {
      data {
        id
      }
    }
  }
`

export const APPROVE_READING_MUTATION = gql`
  mutation approveReading($id: ID!, $publishedAt: DateTime) {
    updateReading(id: $id, data: {publishedAt: $publishedAt}) {
      data {
        id
      }
    }
  }
`

export const DELETE_READING_MUTATION = gql`
  mutation DeleteReading($id: ID!) {
    deleteReading(id: $id) {
      data {
        id
      }
    }
  }
`


export const PINNED_READING_QUERY = gql`
  query {
    pinnedReading {
      data {
        id
        attributes {
          title
          pdf_url
          thumbnail_url
          pages
        }
      }
    }
  }
`;
