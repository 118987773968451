import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {Typeahead} from 'react-bootstrap-typeahead'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import moment from 'moment'
import {Format} from '../../helpers/constants'
import {useCallback, useEffect} from 'react'
import {CITIES} from '../../helpers/cities'
import {TIMEZONES} from '../../helpers/timezone'
import {updateEvent} from '../../query/api/crypto311'
import {debounce} from 'lodash'

const EventSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(40, 'Maximum 40 symbols')
    .required('Title is required'),
  description: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Description is required'),
  format: Yup.string().required('Format is required'),
  city: Yup.string().when('format', (format) =>
    format === Format.VIRTUAL ? Yup.string() : Yup.string().required('City is required')
  ),
  region: Yup.string().when('format', (format) =>
    format === Format.VIRTUAL ? Yup.string() : Yup.string().required('Region is required')
  ),
  mainSide: Yup.boolean().required('Main or Side is required'),
  // imageUrl: Yup.string().matches(urlRegex, 'Invalid Image URL').required('Image URL is required'),
  pageUrl: Yup.string().required('Page URL is required'),
  fromDate: Yup.string().required('Form Date is required'),
  endDate: Yup.string().required('End Date is required'),
  timezone: Yup.string().required('Timezone is required'),
})

export const EventFormUpdateModal = ({
  showUpdateEvent,
  setShowUpdateEvent,
  data,
  setData,
  refetch,
}: {
  showUpdateEvent: boolean
  setShowUpdateEvent: (showUpdateEvent: boolean) => void
  data: any
  setData: (data: any) => void
  refetch: () => void
}) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      imageUrl: '',
      fromDate: '',
      endDate: '',
      format: '',
      city: '',
      timezone: '',
      region: 'USA',
      pageUrl: '',
      mainSide: false,
      record: false,
    },
    validationSchema: EventSchema,
    onSubmit: async (values, {setSubmitting, resetForm, setFieldError}) => {
      try {
        setSubmitting(true)
        const after18months = moment().add(18, 'M')

        const fd = moment(values.fromDate, 'YYYY-MM-DDTHH:mm')
        const ed = moment(values.endDate, 'YYYY-MM-DDTHH:mm')

        if (fd.valueOf() >= ed.valueOf()) {
          setFieldError('fromDate', `From Date must be before End date`)
          setFieldError('endDate', `End Date must be after date From Date`)
          return
        }

        if (fd.isAfter(after18months)) {
          setFieldError(
            'fromDate',
            `From Date must be before date ${after18months.format('YYYY-MM-DD')}`
          )
          return
        }

        if (ed.isAfter(after18months)) {
          setFieldError(
            'endDate',
            `End Date must be before date ${after18months.format('YYYY-MM-DD')}`
          )
          return
        }

        await updateEvent(data.id, {
          ...values,
          fromDate: fd.format('MM/DD/YYYY LT'),
          endDate: ed.format('MM/DD/YYYY LT'),
          region: values.format === Format.VIRTUAL ? '' : values.region,
        })

        await refetch()
        setSubmitting(false)
        setShowUpdateEvent(false)
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })

  const setInputData = () => {
    formik.resetForm()
    formik.setFieldValue('title', data.title)
    formik.setFieldValue('description', data.description)
    formik.setFieldValue('imageUrl', data.imageUrl)
    formik.setFieldValue('fromDate', moment(data.fromDate, 'MM/DD/YYYY LT').format('YYYY-MM-DDTHH:mm'))
    formik.setFieldValue('endDate', moment(data.endDate, 'MM/DD/YYYY LT').format('YYYY-MM-DDTHH:mm'))
    formik.setFieldValue('region', data.region)
    formik.setFieldValue('mainSide', data.mainSide)
    formik.setFieldValue('record', data.record)
    formik.setFieldValue('pageUrl', data.pageUrl)

    formik.setFieldValue('format', data.format)
    formik.setFieldValue('city', data.city)
    formik.setFieldValue('timezone', data.timezone)
  }

  useCallback(() => {
    setInputData()
  }, [data])

  useEffect(() => {
    setInputData()
  }, [data])

  return (
    <>
      <Modal
        size='lg'
        show={showUpdateEvent}
        onHide={() => {
          formik.resetForm()
          setData({})
          setShowUpdateEvent(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='form bg-white'
            onSubmit={formik.handleSubmit}
            noValidate
            onChange={() => formik.setStatus('')}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='row gy-3 gx-5'>
              <div className='col-12'>
                <label className='form-label'>Title</label>
                <input
                  placeholder='Title'
                  {...formik.getFieldProps('title')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.title && formik.errors.title},
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                  type='text'
                  name='title'
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.title}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Description</label>
                <input
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  type='text'
                  name='description'
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Image URL</label>
                <input
                  placeholder='Image URL'
                  {...formik.getFieldProps('imageUrl')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.imageUrl && formik.errors.imageUrl},
                    {
                      'is-valid': formik.touched.imageUrl && !formik.errors.imageUrl,
                    }
                  )}
                  type='text'
                  name='imageUrl'
                />
                {formik.touched.imageUrl && formik.errors.imageUrl && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.imageUrl}</span>
                  </div>
                )}
              </div>

              <div className='col-4'>
                <label className='form-label'>From Date</label>
                <input
                  placeholder='From Date'
                  {...formik.getFieldProps('fromDate')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.fromDate && formik.errors.fromDate},
                    {
                      'is-valid': formik.touched.fromDate && !formik.errors.fromDate,
                    }
                  )}
                  type='datetime-local'
                  name='fromDate'
                />
                {formik.touched.fromDate && formik.errors.fromDate && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.fromDate}</span>
                  </div>
                )}
              </div>

              <div className='col-4'>
                <label className='form-label'>End Date</label>
                <input
                  placeholder='End Date'
                  {...formik.getFieldProps('endDate')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.endDate && formik.errors.endDate},
                    {
                      'is-valid': formik.touched.endDate && !formik.errors.endDate,
                    }
                  )}
                  type='datetime-local'
                  name='endDate'
                />
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.endDate}</span>
                  </div>
                )}
              </div>

              <div className='col-4'>
                <label className='form-label'>Timezone</label>

                <Typeahead
                  id='timezone-select'
                  defaultInputValue={data.timezone || ''}
                  inputProps={{
                    className: clsx(
                      {'is-invalid': formik.touched.timezone && formik.errors.timezone},
                      {
                        'is-valid': formik.touched.timezone && !formik.errors.timezone,
                      }
                    ),
                  }}
                  maxHeight='200px'
                  highlightOnlyResult
                  onChange={(select) => {
                    formik.setFieldValue('timezone', select[0])
                  }}
                  options={TIMEZONES}
                  placeholder='Select timezone..'
                />
                {formik.touched.timezone && formik.errors.timezone && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.timezone}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Format</label>

                <Typeahead
                  id='format-select'
                  defaultInputValue={data.format || ''}
                  inputProps={{
                    className: clsx(
                      {'is-invalid': formik.touched.city && formik.errors.city},
                      {
                        'is-valid': formik.touched.city && !formik.errors.city,
                      }
                    ),
                  }}
                  onChange={(select) => {
                    formik.setFieldValue('format', select[0])
                  }}
                  options={[Format.IN_PERSION, Format.HYBRID, Format.VIRTUAL]}
                  placeholder='Select Format..'
                />
                {formik.touched.format && formik.errors.format && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.format}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>City</label>
                <Typeahead
                  id='city-select'
                  defaultInputValue={data.city || ''}
                  disabled={formik.values.format === Format.VIRTUAL}
                  maxHeight='200px'
                  inputProps={{
                    className: clsx(
                      {'is-invalid': formik.touched.city && formik.errors.city},
                      {
                        'is-valid': formik.touched.city && !formik.errors.city,
                      }
                    ),
                  }}
                  highlightOnlyResult
                  onChange={(select) => {
                    formik.setFieldValue('city', select[0])
                  }}
                  options={CITIES}
                  placeholder='Select city..'
                />
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.city}</span>
                  </div>
                )}
              </div>

              <div className='col-12'>
                <label className='form-label'>Page URL</label>
                <input
                  placeholder='Page URL'
                  {...formik.getFieldProps('pageUrl')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.pageUrl && formik.errors.pageUrl},
                    {
                      'is-valid': formik.touched.pageUrl && !formik.errors.pageUrl,
                    }
                  )}
                  type='text'
                  name='pageUrl'
                />
                {formik.touched.pageUrl && formik.errors.pageUrl && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.pageUrl}</span>
                  </div>
                )}
              </div>

              <div className='col-6 d-flex mt-4'>
                <label className='form-label me-2 '>Check if a Main event</label>
                <input
                  placeholder='Check if a Main event'
                  {...formik.getFieldProps('mainSide')}
                  className={clsx(
                    'form-check-input form-check text-back',
                    {'is-invalid': formik.touched.mainSide && formik.errors.mainSide},
                    {
                      'is-valid': formik.touched.mainSide && !formik.errors.mainSide,
                    }
                  )}
                  type='checkbox'
                  name='mainSide'
                />
                {formik.touched.mainSide && formik.errors.mainSide && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.mainSide}</span>
                  </div>
                )}
              </div>

              <div className='col-6 d-flex mt-4'>
                <label className='form-label  me-2'>Recording available after event</label>
                <input
                  placeholder='Recording available after event'
                  {...formik.getFieldProps('record')}
                  className={clsx(
                    'form-check-input form-check text-back',
                    {'is-invalid': formik.touched.record && formik.errors.record},
                    {
                      'is-valid': formik.touched.record && !formik.errors.record,
                    }
                  )}
                  type='checkbox'
                  name='record'
                />
                {formik.touched.record && formik.errors.record && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.record}</span>
                  </div>
                )}
              </div>

              {/* <div className='d-grid mt-8'>
                <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? (
                    <span data-kt-search-element='spinner'>
                      <span className='spinner-border h-25px w-25px align-middle' />
                    </span>
                  ) : (
                    <span className='indicator-label'>Submit</span>
                  )}
                </button>
              </div> */}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <button className='btn btn-secondary p-3' onClick={() => setShowUpdateEvent(false)}>
            Cancel
          </button>
          <button className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
