import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_EVENTHUB_URL

type Balance = {
  balance: string
  buying_liabilities: string
  selling_liabilities: string
  asset_type: string
}

export const getParties = async (): Promise<any> => {
  const endpoint = `${API_URL}/parties`
  return axios.get(endpoint)
}

export const postParties = async ({name, email}: {name: string; email: string}): Promise<any> => {
  const endpoint = `${API_URL}/parties`
  return axios.post(endpoint, {name, email})
}

export const getAdminBalances = async (): Promise<AxiosResponse<Balance[]>> => {
  const endpoint = `${API_URL}/contract/admin-balance`
  return axios.get(endpoint)
}

export const batchTransfer = async (parties: string[], amount: string) => {
  const endpoint = `${API_URL}/contract/batch-transfer`
  return axios.post(endpoint, {
    pks: parties,
    amount,
  })
}
