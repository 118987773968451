import {QueryResult, useMutation, useQuery} from '@apollo/client'
import {Column} from 'react-table'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import {Table} from '../../components'
import {useAuth} from '../auth'
import {IReadingData} from '../../query/interface/IReading'
import {APPROVE_READING_MUTATION, DELETE_READING_MUTATION, READING_QUERY} from '../../query/Reading'
import { ORGANIZATION_QUERY } from '../../query/Organization'

type CustomColumn = {
  className?: string
} & Column

export const OrganizationTable = () => {
  const {data, loading, refetch} = useQuery(ORGANIZATION_QUERY)

  

  const columns: CustomColumn[] = [
    {
      className: 'min-w-150px',
      Header: 'ID',
      Cell: (cell: any) => {
        return <>{cell.row.original?.id}</>
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Organization Name',
      Cell: (cell: any) => {
        return <>{cell.row.original?.attributes?.name}</>
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Organizations'
        columns={columns}
        data={data?.organizations.data || []}
        isLoading={loading}
      />
    </>
  )
}
