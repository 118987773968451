import {ICBDCPulseData} from '../../query/interface/ICBDCPulse'
import {CBDCPulseTable} from '../../modules/monitors/CBDCPulseTable'
import {QueryResult, useQuery} from '@apollo/client'
import {CBDC_PULSE_QUERY, STABLE_COIN_PULSE_QUERY} from '../../query/Monitor'
import { StablecoinPulseTable } from '../../modules/monitors/StablecoinPulseTable'
import { IStablecoinPulseData } from '../../query/interface/IStablecoinPulse'

export const MonitorWrapper = () => {
  const {
    data: cbdcData,
    loading: cbdcLoading,
    refetch: cbdcRefetch,
  }: QueryResult<ICBDCPulseData> = useQuery(CBDC_PULSE_QUERY)

  const {
    data: scData,
    loading: scLoading,
    refetch: scRefetch,
  }: QueryResult<IStablecoinPulseData> = useQuery(STABLE_COIN_PULSE_QUERY)


  return (
    <>
      <CBDCPulseTable data={cbdcData} loading={cbdcLoading} refetch={cbdcRefetch} />
      <StablecoinPulseTable data={scData} loading={scLoading} refetch={scRefetch} />
    </>
  )
}
