import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {putResetPassword} from '../../query/api/backend'

const Datasetchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Name  is required'),
})

const initialValues = {
  password: '',
}

export const ResetPasswordModal = ({
  accountId,
  showResetPassword,
  setShowResetPassword,
  setReload,
}: {
  accountId: number
  showResetPassword: boolean
  setShowResetPassword: any
  setReload: any
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema: Datasetchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)
      const payload = {
        password: values.password,
      }
      try {
        await putResetPassword(accountId, payload)

        setShowResetPassword(false)
        resetForm()
        setReload(true)
      } catch (error: any) {
        setStatus('Error: ' + error.message)
      }
    },
  })

  return (
    <>
      <Modal size='sm' show={showResetPassword} onHide={() => setShowResetPassword(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            onChange={() => formik.setStatus('')}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <div className='row gy-3 gx-5'>
              <div className='col-12'>
                <label className='form-label'>New Password</label>
                <input
                  placeholder='New Password'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  type='password'
                  name='password'
                  autoComplete='off'
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                )}
              </div>

              {/* <div className='d-grid mt-8'>
                <button type='submit' className='btn btn-primary'>
                  <span className='indicator-label'>Upload</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div> */}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className='p-2'>
          <button className='btn btn-secondary p-3' onClick={() => setShowResetPassword(false)}>
            Close
          </button>
          <button className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
