/** @format */

import {gql} from '@apollo/client'

export const SOURCE_QUERY = gql`
  query getSources {
    sources {
      data {
        id
        attributes {
          name
          home_url
        }
      }
    }
  }
`
