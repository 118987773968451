import clsx from 'clsx'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {postSpeaker} from '../_request'

const speakerSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  title: Yup.string().required('Title is required'),
  linkedinUrl: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .required('Please enter linkedin url'),
  bio: Yup.string().required('Bio Name is required'),
})

type Props = {
  show: boolean
  setShow: (show: boolean) => void
  setRefetch: (refetch: boolean) => void
}

export const AddSpeakerModal = ({show, setShow, setRefetch}: Props) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      title: '',
      linkedinUrl: '',
      bio: '',
    },
    validationSchema: speakerSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        setSubmitting(true)

        const payload = {
          name: values.name,
          title_tx: values.title,
          linkedin_url: values.linkedinUrl,
          bio_tx: values.bio,
        }

        await postSpeaker(payload)
        setSubmitting(false)
        setRefetch(true)
        setShow(false)
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })
  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        setShow(false)
        formik.resetForm()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>New Speaker</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: 0}}>
        <div className='card'>
          <div className='card-body'>
            <form
              className='form w-100 bg-white'
              onSubmit={formik.handleSubmit}
              noValidate
              onChange={() => formik.setStatus('')}
            >
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='row gy-3 gx-5'>
                <div className='col-12'>
                  <label className='form-label'>Name</label>
                  <input
                    placeholder='Name'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  )}
                </div>

                <div className='col-12'>
                  <label className='form-label'>Title</label>
                  <input
                    placeholder='Title'
                    {...formik.getFieldProps('title')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.title && formik.errors.title},
                      {
                        'is-valid': formik.touched.title && !formik.errors.title,
                      }
                    )}
                    type='text'
                    name='title'
                    autoComplete='off'
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.title}</span>
                    </div>
                  )}
                </div>

                <div className='col-12'>
                  <label className='form-label'>Linkedin Url</label>
                  <input
                    placeholder='Linkedin Url'
                    {...formik.getFieldProps('linkedinUrl')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.linkedinUrl && formik.errors.linkedinUrl},
                      {
                        'is-valid': formik.touched.linkedinUrl && !formik.errors.linkedinUrl,
                      }
                    )}
                    type='text'
                    name='linkedinUrl'
                    autoComplete='off'
                  />
                  {formik.touched.linkedinUrl && formik.errors.linkedinUrl && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.linkedinUrl}</span>
                    </div>
                  )}
                </div>

                <div className='col-12'>
                  <label className='form-label'>Bio</label>
                  <input
                    placeholder='Bio'
                    {...formik.getFieldProps('bio')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.bio && formik.errors.bio},
                      {
                        'is-valid': formik.touched.bio && !formik.errors.bio,
                      }
                    )}
                    type='text'
                    name='bio'
                    autoComplete='off'
                  />
                  {formik.touched.bio && formik.errors.bio && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.bio}</span>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='p-2'>
        <button className='btn btn-secondary p-3' onClick={() => setShow(false)}>
          Close
        </button>
        <button type='submit' className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
          {formik.isSubmitting ? (
            <span data-kt-search-element='spinner'>
              <span className='spinner-border h-25px w-25px align-middle' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
