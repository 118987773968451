import clsx from 'clsx'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {postVenue} from '../_request'

const venueSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'), 
})

type Props = {
  show: boolean
  setShow: (show: boolean) => void
  setRefetch: (refetch: boolean) => void
}

export const AddVenueModal = ({show, setShow, setRefetch}: Props) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
    },
    validationSchema: venueSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        setSubmitting(true)

        const payload = {
          short_name_tx: values.name,
          address_tx: values.address,
        }

        await postVenue(payload)
        setSubmitting(false)
        setRefetch(true)
        setShow(false)
        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })
  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        setShow(false)
        formik.resetForm()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>New Venue</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: 0}}>
        <div className='card'>
          <div className='card-body'>
            <form
              className='form w-100 bg-white'
              onSubmit={formik.handleSubmit}
              noValidate
              onChange={() => formik.setStatus('')}
            >
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='row gy-3 gx-5'>
                <div className='col-12'>
                  <label className='form-label'>Name</label>
                  <input
                    placeholder='Name'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  )}
                </div>

                <div className='col-12'>
                  <label className='form-label'>Address</label>
                  <input
                    placeholder='Name'
                    {...formik.getFieldProps('address')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.address && formik.errors.address},
                      {
                        'is-valid': formik.touched.address && !formik.errors.address,
                      }
                    )}
                    type='text'
                    name='address'
                    autoComplete='off'
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.address}</span>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='p-2'>
        <button className='btn btn-secondary p-3' onClick={() => setShow(false)}>
          Close
        </button>
        <button type='submit' className='btn btn-primary p-3' onClick={() => formik.submitForm()}>
          {formik.isSubmitting ? (
            <span data-kt-search-element='spinner'>
              <span className='spinner-border h-25px w-25px align-middle' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
