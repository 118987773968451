import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_EVENTHUB_URL

export const getVenues = async (): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/venues`
  return axios(endpoint)
}

export const postVenue = async (payload: any): Promise<AxiosResponse<any>> => {
  const endpoint = `${API_URL}/venues`
  return axios.post(endpoint, payload)
}
